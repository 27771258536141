import React from "react";
import { Route } from "react-router-dom";
import { redirect } from "./routing-constants";
import { getFromLocal } from "../utils/cache";
import { LOCAL_STORAGE_KEYS } from "../utils/constants";

export default function PrivateRoute({ children, ...rest }) {
  return (
    <>
      <Route
        {...rest}
        render={({ location }) => {
          const token = getFromLocal(LOCAL_STORAGE_KEYS.TOKEN, false, true);
          if (token) {
            return children;
          } else {
            return redirect("/login", location);
          }
        }}
      />
    </>
  );
}
