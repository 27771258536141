import * as ActionTypes from "../actions/Types";

const initialState = {
  filters: {},
  report: {},
  currentReportIndex: 0
};

export const reports = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_REPORT:
      return {
        ...state,
        report: action.payload
      };
    case ActionTypes.SET_FILTERS:
      return {
        ...state,
        filters: action.payload
      }
    case ActionTypes.SET_CURRENT_REPORT_INDEX:
      return {
        ...state,
        currentReportIndex: action.payload ? action.payload : 0
      }
    case ActionTypes.LOGOUT: 
      return {
        filters: {},
        report: {},
        currentReportIndex: 0
      }
    default:
      return state;
  }
};
