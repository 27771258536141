import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Table, Typography, Card, Radio } from "antd";

const RecentsTable = ({ initialData, title, tabs }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(initialData);
  const [selectedOpt, setSelectedOpt] = useState(tabs[0]);
  useEffect(() => {
    setSelectedOpt(tabs[0]);
  }, [tabs]);

  useEffect(() => {
    setData(initialData);
    setLoading(false);
  }, [initialData]);

  const { Title } = Typography;
  return (
    <Card
      style={{ width: "100%" }}
      className="dashboad-table-box dashboard-card-t1"
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Title level={3}>{title}</Title>

        {tabs && tabs[0].type && (
          <div className="db-tabs-buttons-wrapper">
            {tabs.map((tab) => (
              <span
                onClick={() => {
                  setSelectedOpt(tab);
                }}
                className={selectedOpt.type === tab.type ? "activateLink" : ""}
              >
                {tab.type}
              </span>
            ))}
          </div>
        )}
      </div>
      <Table
        loading={loading}
        style={{ width: "100%" }}
        columns={selectedOpt.columns}
        dataSource={selectedOpt.data}
        pagination={false}
      />
    </Card>
  );
};

export default RecentsTable;
