import React from "react";
import { Row, Col, Table, Button, Avatar, Typography, Space } from "antd";
import "./Invoice.scss";
import dummyVendorLogo from "../../assets/images/abc-aus.jpg";

const { Text } = Typography;

const dataSource = [
  {
    key: "1",
    number: "1",
    sku: "Adobe Suite",
    subscriptions: "23",
    usersPerSubscription: "01",
    priceUnit: "$200",
    discount: "5%",
    finalPrice: "$8000",
  },
  {
    key: "2",
    number: "2",
    sku: "Microsoft Office",
    subscriptions: "10",
    usersPerSubscription: "02",
    priceUnit: "$80",
    discount: "10%",
    finalPrice: "$10,000",
  },
  {
    key: "3",
    number: "3",
    sku: "Sketch",
    subscriptions: "05",
    usersPerSubscription: "01",
    priceUnit: "$250",
    discount: "3%",
    finalPrice: "$2000",
  },
];

const columns = [
  {
    title: "#",
    dataIndex: "number",
    key: "number",
  },
  {
    title: "SKU",
    dataIndex: "sku",
    key: "sku",
  },
  {
    title: "Subscriptions",
    dataIndex: "subscriptions",
    key: "subscriptions",
  },
  {
    title: "Users Per Subscription",
    dataIndex: "usersPerSubscription",
    key: "usersPerSubscription",
  },
  {
    title: "List Price",
    dataIndex: "priceUnit",
    key: "priceUnit",
  },
  {
    title: "Extended List Price",
    dataIndex: "finalPrice",
    key: "finalPrice",
  },
  {
    title: "Discount(%)",
    dataIndex: "discount",
    key: "discount",
  },
];

const InvoiceDetails = () => {
  return (
    <React.Fragment>
      <Row className="content-header">
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <h2>Invoice Details</h2>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          className="d-flex f-justify-end"
        >
          <Space>
            <Text className="f-info-label">Date</Text>
            <Text className="f-info-value">16/02/2020</Text>
          </Space>
        </Col>
      </Row>

      <Row className="">
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Row className="">
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              className="select-vendor-details-wrapper"
            >
              <Avatar src={dummyVendorLogo} className="vendor-logo" />
              <div className="address-details">
                <p className="label-vendor-name">ABC Australia</p>
                <p className="label-vendor-number">+1 (575) 099 5820</p>
                <p>957 Linda Drive New Kensington, PA United States</p>
              </div>
            </Col>
          </Row>
        </Col>

        <Col
          xs={24}
          m={24}
          md={12}
          lg={12}
          xl={12}
          className="info-invoice-number"
        >
          <Row className="" gutter={[24, 12]} align="center">
            <Col xs={24} sm={24} md={14} lg={14} xl={14}>
              <Text className="f-info-label">Invoice Issue Date</Text>
            </Col>
            <Col xs={24} sm={24} md={10} lg={10} xl={10}>
              <Text className="f-info-value">12/02/2020</Text>
            </Col>
          </Row>

          <Row className="" gutter={[24, 12]} align="center">
            <Col xs={24} sm={24} md={14} lg={14} xl={14}>
              <Text className="f-info-label">Invoice #</Text>
            </Col>
            <Col xs={24} sm={24} md={10} lg={10} xl={10}>
              <Text className="f-info-value">N5424</Text>
            </Col>
          </Row>

          <Row className="" gutter={[24, 12]} align="center">
            <Col xs={24} sm={24} md={14} lg={14} xl={14}>
              <Text className="f-info-label">Status</Text>
            </Col>
            <Col xs={24} sm={24} md={10} lg={10} xl={10}>
              <Text className="f-info-value status-tag-p1">
                Approved w/ discrepancy
              </Text>
            </Col>
          </Row>
        </Col>
      </Row>

      <h4 className="mt-1">Billing Details</h4>

      <Row className="form-view-t2 cus-section-mb-t1" gutter={24} align="left">
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Row className="mb-1">
            <Col className="col-label">
              <Text className="f-info-label">Contact Person</Text>
            </Col>
            <Col className="col-value">
              <Text className="f-info-value">Alba John </Text>
            </Col>
          </Row>
          <Row>
            <Col className="col-label">
              <Text className="f-info-label">Contact Number </Text>
            </Col>
            <Col className="col-value">
              <Text className="f-info-value">+1 (413) 930 0234</Text>
            </Col>
          </Row>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Row className="mb-1">
            <Col className="col-label">
              <Text className="f-info-label">Email</Text>
            </Col>
            <Col className="col-value">
              <Text className="f-info-value">alba.john@abc.com</Text>
            </Col>
          </Row>
          <Row>
            <Col className="col-label">
              <Text className="f-info-label">Address</Text>
            </Col>
            <Col className="col-value">
              <Text className="f-info-value">
                957 Linda Drive New Kensington, PA, United States
              </Text>
            </Col>
          </Row>
        </Col>
      </Row>

      <h4 className="mt-1">SKU Details</h4>

      <Table
        className="table-t2"
        size="middle"
        columns={columns}
        dataSource={dataSource}
        pagination={{ position: ["none", "none"] }}
        summary={(pageData) => {
          let totalBorrow = 0;
          let totalRepayment = 0;

          pageData.forEach(({ borrow, repayment }) => {
            totalBorrow += borrow;
            totalRepayment += repayment;
          });

          return (
            <>
              {/* <Table.Summary.Row className="invoice-total-wrapper">
                <Table.Summary.Cell
                  colSpan={6}
                  className="itw-row-1 align-right pr-2"
                >
                  VAT (13%)
                </Table.Summary.Cell>
                <Table.Summary.Cell colSpan={1} className="itw-row-1">
                  <Text type="" className="bold">
                    35%
                  </Text>
                </Table.Summary.Cell>
              </Table.Summary.Row> */}

              <Table.Summary.Row className="invoice-total-wrapper">
                <Table.Summary.Cell
                  colSpan={6}
                  className="itw-row-2 align-right label-total-amount pr-2"
                >
                  Total Amount
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  colSpan={2}
                  className="itw-row-2 label-amount"
                >
                  <Text type="">$20,000</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />

      <Row className="mt-2" gutter={24} align="top">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <h6 className="sub-ht3">Attachments</h6>
          No file Attachments
        </Col>
      </Row>

      <Row className="mt-2" gutter={24} align="top">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Button className="btn-primary btn-t2 mr-1">
            <span class="btn-icon icon-arrow-square-left"></span> Back
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default InvoiceDetails;
