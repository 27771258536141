import React, { useState, useEffect } from "react";
import moment from "moment";
import { Table, Tag } from "antd";
import { badgeColor } from "../../Components/PO/POStatus";

const columns = [
  {
    title: "User",
    dataIndex: "user",
    render: (user) => user && <p>{`${user.first_name} ${user.last_name}`}</p>,
  },
  {
    title: "Date",
    dataIndex: "createdAt",
    render: (time) => time && moment(time).format("LLL"),
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (text) => {
      let color;
      for (let prop in badgeColor)
        if (prop === text) {
          color = badgeColor[prop];
        }
      return (
        <div style={{ textTransform: "capitalize" }}>
          <Tag color={color}>{text}</Tag>
        </div>
      );
    },
  },
  {
    title: "Comment",
    dataIndex: "comment",
  },
];
const CommentsData = ({ data }) => {
  const [commentData, setCommentData] = useState([]);
  useEffect(() => {
    setCommentData(data);
  }, [data]);

  if (data && data.length < 1) return <p>No history found.</p>;
  return (
    <Table columns={columns} dataSource={commentData} pagination={false} />
  );
};

export default CommentsData;
