import React from "react";
import { Typography, Card, Row, Col } from "antd";
import Loader from "../Loader";

const { Title } = Typography;

const SimpleCardDashboard = ({ unitSymbol, unit, title, boxClass }) => {
  return (
    <Row>
      <Col xl={24}>
        <div className={`number-data ${boxClass}`}>
          <div className="d-flex">
            {/* <div className="ico-box-rounded">{svg}</div> */}
            {unit || unit == 0 ? <Title>{unitSymbol}{unit}</Title> : <Loader />}
          </div>
          <Title level={4}>{title}</Title>
        </div>
      </Col>
    </Row>
  );
};

export default SimpleCardDashboard;
