import React from "react";
import { Typography, Row, Col } from "antd";
import { Gauge } from "@ant-design/charts";

const Meter = ({ score, title, details }) => {
  const { Title, Text } = Typography;

  const ticks = [0, 1 / 3, 2 / 3, 1];
  const color = ["#F4664A", "#FAAD14", "#30BF78"];
  const config = {
    startAngle: Math.PI,
    endAngle: 2 * Math.PI,
    percent: score.total / 100,
    height: 75,
    range: {
      ticks: [0, 1],
      color: ["l(0) 0:#F4664A 0.5:#FAAD14 1:#30BF78"],
    },
    indicator: {
      pointer: {
        style: {
          lineWidth: 2,
          fill: "#423f3f",
          stroke: "#423f3f",
        },
      },
      pin: {
        style: {
          r: 3,
          lineWidth: 1,
          stroke: "#423f3f",
        },
      },
    },
    axis: {
      label: {
        formatter(v) {
          return Number(v) * 100;
        },
      },
      subTickLine: {
        count: 3,
      },
    },
    statistic: {
      title: {
        formatter: ({ percent }) => {
          if (percent < ticks[1]) {
            //return '30';
          }

          if (percent < ticks[2]) {
            //return '70';
          }

          //return '100';
        },
        style: ({ percent }) => {
          return {
            fontSize: "36px",
            lineHeight: 1,
            color: percent < ticks[1] ? color[0] : percent < ticks[2] ? color[1] : color[2],
          };
        },
      },
      content: {
        offsetY: 36,
        style: {
          fontSize: "24px",
          color: "#4B535E",
        },
        formatter: () => "",
      },
    },
  };

  return (
    <Row gutter={[12, 24]} className="db-row right-score-card-wrapper" style={{ alignItems: "center" }}>
      <Col xs={24} sm={8} md={18} lg={8} xl={8}>
        <Title className="m-1 light" style={{ fontSize: "16px", wordBreak: "auto-phrase", wordWrap: "normal" }}>
          {title}
        </Title>
        <Title level={5} className="m-0" style={{ fontSize: "14px" }}>
          {score.total}
        </Title>
      </Col>
      <Col xs={24} sm={16} md={16} lg={16} xl={16}>
        <div className="vendor-score-meter-wrapper">
          <div className="flip-card-inner">
            <div className="vendorScore-meter-block">
              <Gauge {...config} />
            </div>
            <div className="vendorScore-breakdown-block">
              <p>
                Contract Score: <b>{score.contract_score}</b>
              </p>
              <p>
                Discrepancy Score: <b>{score.discrepancies_score}</b>
              </p>
              <p>
                Unit Cost Score: <b>{score.unit_cost_score}</b>
              </p>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Meter;
