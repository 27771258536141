export const percentageChange = (newValue, oldValue) => ((newValue - oldValue) / oldValue) * 100;

export const findByItem = (data, key, value) => data?.find((item) => item[key] === value);

export function groupDataByYear(data) {
  const groupedData = [];

  // Iterate over each entry in the original data
  data.forEach((entry) => {
    // Extract common information for both years
    const commonInfo = {
      siteName: entry.siteName,
      city: entry.city_2022,
      state: entry.state_2022,
    };

    // Create a new entry for each year
    const entry2022 = { ...commonInfo, year: "2022", type: "A", spend: Math.ceil(entry.Spend2022), ...entry };
    const entry2023 = { ...commonInfo, year: "2023", type: "B", spend: Math.ceil(entry.Spend2023), ...entry };

    // Push the entries for both years into the groupedData array
    groupedData.push(entry2022, entry2023);
  });

  return groupedData;
}

export const amount = new Intl.NumberFormat("en-US", {
  notation: "compact",
  // currency: "USD",
});
