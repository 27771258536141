import React from "react";
import moment from "moment";
import TableRows from "./TableRows";
import TableHeader from "./TableHeader";
import AppLogo from "../assets/images/app-logo.png";
import {
  Text,
  Document,
  Page,
  StyleSheet,
  Image,
  View,
} from "@react-pdf/renderer";

export default ({
  columns,
  data,
  reportTitle,
  label,
  img = false,
  counterFields = [],
}) => {
  return (
    <Document style={styles.document}>
      <Page style={styles.body} size="A3">
        <View style={styles.header}>
          <Image src={AppLogo} style={{ height: "50px", width: "200px" }} />
          <Text style={styles.headerText}>{moment().format("YYYY-MM-DD")}</Text>
        </View>
        <Text style={styles.subtitle}>{reportTitle}</Text>
        {/* {img && <Image src={img} style={{ height: "300px", width: "500px" }} />} */}
        <TableHeader columns={columns} />
        <TableRows items={data.rows} label={label} />
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  document: {
    marginTop: 40,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 15,
    textAlign: "center",
    marginBottom: 30,
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  headerText: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});
