export const LOGIN_ROUTE = "/login";
export const FORGOT_PASSWORD_ROUTE = "/forgot-password";
export const RESET_PASSWORD_ROUTE = "/reset-password";
export const DEMO_FORM = "/demo-request";

export const USER_PROFILE_ROUTE = "/profile";

export const DASHBOARD_ROUTE = "/";
export const UPLOAD_CONTRACT_ROUTE = "/contracts/upload";
export const VIEW_CONTRACTS_ROUTE = "/contracts/view";
export const SELECT_CONTRACT_TEMPLATE_ROUTE = "/contracts/:contractId/select-template";
export const CONTRACT_TEMPLATE = "/contract/:contractId/template/:id";
export const EDIT_TEMPLATED_CONTRACT = "/contract/edit/:id";
export const VIEW_TEMPLATED_CONTRACT = "/contract/view/:id";

export const VIEW_INVOICES_ROUTE = "/invoices/view";
export const ADD_INVOICE_ROUTE = "/invoices/add/";
export const UPLOAD_INVOICE_ROUTE = "/invoices/upload";
export const INVOICE_DETAILS_ROUTE = "/invoices/details";
export const INVOICE_DISCREPANCY_APPROVER_ROUTE = "/invoices/approve/";

//po -> purchase order
export const VIEW_PO_ROUTE = "/po/view";
export const VIEW_PO_COMPLIANCE_ROUTE = "/po-compliance/view";

export const UPLOAD_PO_ROUTE = "/po/upload";
export const ADD_PO_ROUTE = "/po/add/";
export const PO_DISCREPANCY_APPROVER_ROUTE = "/po/approve/";
export const ADD_PO_MANUALLY = "/po/manual/add";

export const VIEW_REPORTS_ROUTE = "/reports/view";

export const VIEW_REBATE_SCORECARD = "/rebate/scorecard";
export const VIEW_SKU_ANALYTICS = "/sku/analytics";

export const DATA_UPLOAD = "/data/upload";
