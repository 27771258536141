import HttpRequest from "../../utils/httpRequests";
import endPoints from "../../utils/endPoints";

export const createPOs = (data) => {
    return HttpRequest("post", endPoints.CREATE_PURCHASE_ORDER, data);
}

export const getPOList = (org_id, params) => {
  return HttpRequest("get", `${endPoints.VIEW_PURCHASE_ORDERS}${org_id}`, null , null , params);
}

export const getPO = (id) => {
  return HttpRequest("get", `${endPoints.GET_PO_BY_ID}${id}`);
};

export const updatePO = (id, data) => {
  return HttpRequest("put", `${endPoints.UPDATE_PO_BY_ID}${id}`, data);
};

export const updatePOAndPOItems = (id, data) => {
  return HttpRequest("put", `${endPoints.UPDATE_PO_WITH_PO_ITEMS_BY_ID}${id}`, data);
};

export const recalculatePODiscrepancy = (id, data ) => {
  return HttpRequest("post", `${endPoints.RECHECK_FOR_PO_DISCREPANCY}${id}`, data);
};

export const removePO = (id) => {
  return HttpRequest("delete", `${endPoints.DELETE_PO}${id}`);
};

export const getPOListWithDisc = (org_id, params) => {
  return HttpRequest(
    "get",
    `${endPoints.GET_POS_WITH_DISC}${org_id}`,
    null,
    null,
    {...params, type: "PO_CONTRACT"}
  );
};

export const createDiscrepancyFoundInPO = (data) => {
  return HttpRequest("post", endPoints.CREATE_PO_DISCREPANCY, data);
}

export const createPoManuallyAPI = data => {
  return HttpRequest("post", endPoints.CREATE_PO_MANUALLY, data);
}

export const updatePOStatusWithComment = (id, data) => {
  return HttpRequest("put", `${endPoints.UPDATE_PO_COMMENT_STATUS}${id}`, data);
}
export const getPosForManager = (org_id, params) => {
  return HttpRequest("get", `${endPoints.GET_PO_FOR_MANAGER}${org_id}`, null , null , params);
}