import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers/rootReducer";
import { createLogger } from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";

const logger = createLogger();
const middlewares = [thunk];

if (process.env.REACT_APP_STAGE === "dev") {
  middlewares.push(logger);
}
export const ConfigureStore = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);
