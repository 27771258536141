import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Notification from "../../Common/Notification";
import Loader from "../../Common/Loader";
import { HIDE_NOTIFICATION } from "../../actions/Types";
import { Form, Input, Button, Table } from "antd";
import NumberFormat from "react-number-format";
import {
  actionDispatch,
  createInvoices,
  getVendors,
} from "../../actions/Creators";
import VendorDropdown from "../../Common/VendorDropdown";
import { isEmpty } from "../../utils/isEmpty";

const UploadInvoiceList = ({ files, removeFile }) => {
  const [uploadStatus, setUploadStatus] = useState(false);
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.commonReducer.notification);
  const user = useSelector((state) => state.auth.user);
  const vendor = useSelector((state) => state.vendor);
  const loading = useSelector((state) => state.commonReducer.loader);
  const organization = useSelector(
    (state) => state.header.selectedOrganization
  );

  useEffect(() => {
    dispatch(getVendors(organization.id));
  }, []);

  const onSaveFiles = () => {
    setUploadStatus(true);
    dispatch(createInvoices(files, user.id));
  };
  const columns = [
    {
      title: "File Name",
      dataIndex: "file_key",
      // ellipsis: true,
      width: 200,
    },
    {
      title: "Vendor Name*",
      dataIndex: "vendor_id",
      width: 180,
    },
    {
      title: "Invoice Number",
      dataIndex: "invoiceNumber",
    },
    {
      title: "Amount",
      dataIndex: "amount",
    },
    {
      title: "Associated PO",
      dataIndex: "associated_po",
      
    },
    {
      title: "",
      dataIndex: "remove",
      width: 50,
    },
  ];
  return (
    <>
      {notification.open && notification.message ? (
        <Notification
          msg={notification.message}
          type={notification.type}
          clearMsg={() => {
            dispatch(actionDispatch(HIDE_NOTIFICATION));
          }}
        />
      ) : null}
      <div className="table-file-uploaded">
        <Form onFinish={() => onSaveFiles()}>
          <Table
            columns={columns}
            rowClassName="row-item"
            pagination={false}
            dataSource={files.map((file, index) => {
              file.organization_id = organization && organization.id;
              file.user_id = user.id;
              file.file_key = file.name;
              return {
                key: index,
                file_key: file.name,
                vendor_id: (
                  <VendorDropdown
                    index={index}
                    vendor={vendor}
                    create={true}
                    getValue={(data) => {
                      file.vendorName = data.value;
                      file.vendor_id = data.id;
                    }}
                  />
                ),
                invoiceNumber: (
                  <Form.Item>
                    <Input
                      len={3}
                      maxLength={20}
                      onChange={(e) => (file.invoice_number = e.target.value)}
                    />
                  </Form.Item>
                ),
                amount: (
                  <Form.Item name={`amount ${index}`}>
                    <NumberFormat
                      className="ant-input"
                      thousandSeparator={true}
                      prefix={"$"}
                      decimalScale={2}
                      fixedDecimalScale
                      allowNegative={false}
                      onValueChange={(values) =>
                        (file.amount = values.floatValue)
                      }
                    />
                  </Form.Item>
                ),
                associated_po: (
                  <Form.Item>
                    <Input
                      len={3}
                      maxLength={15}
                      onChange={(e) => (file.associated_po = e.target.value)}
                    />
                  </Form.Item>
                ),
                remove: (
                  <span
                    title="Remove"
                    className="icon-minus"
                    onClick={() => removeFile(index)}
                  ></span>
                ),
              };
            })}
          />
          <Button
            className="btn-primary"
            htmlType="submit"
            loading={loading && uploadStatus}
          >
            <span className="btn-icon icon-add-new"></span> Upload Invoices
          </Button>
        </Form>
      </div>
    </>
  );
};

export default UploadInvoiceList;
