import HttpRequest from "../../utils/httpRequests";
import endPoints from "../../utils/endPoints";

export const getYtdPurchase = () => {
  return HttpRequest("get", endPoints.GET_YTD_PURCHASE);
};

export const getRebateTier = () => {
  return HttpRequest("get", endPoints.GET_REBATE_TIER);
};

export const getRemainingDays = () => {
  return HttpRequest("get", endPoints.GET_REMAINING_DAYS);
};

export const getRebatableSites = () => {
  return HttpRequest("get", endPoints.GET_REBATABLE_SITES);
};

export const getNonRebatableSites = () => {
  return HttpRequest("get", endPoints.GET_NON_REBATABLE_SITES);
};

export const getAllRebatesTier = () => {
  return HttpRequest("get", endPoints.GET_ALL_REBATES_TIER);
};

export const getSitesGeneratingRebates = () => {
  return HttpRequest("get", endPoints.GET_SITES_GENERATING_REBATE);
};

export const getAllRebatesTierRow = (query) => {
  return HttpRequest("get", endPoints.GET_REBATE_TIER_ROW + `?amount=${query}`);
};
