import { Input } from "antd";
import NumberFormat from "react-number-format";

export const baseUrl = "http://demo5.folio3.com:8080/api";

export const ROLES_GENERAL = ["contract-invoice-manager", "trupacta-team", "po-manager", "discrepancy-approver", "contract-upload-manager", "invoice-manager"];

export const ROLES_CONTRACT_VIEW = ["contract-invoice-manager", "trupacta-team", "contract-upload-manager"];

export const ROLES_CONTRACTS_ADD_VIEW = ["contract-invoice-manager", "trupacta-team"];

export const ROLES_INVOICE_VIEW = ["contract-invoice-manager", "discrepancy-approver", "trupacta-team"];

export const ROLES_INVOICE_ADD_VIEW = ["contract-invoice-manager", "trupacta-team"];

export const ROLES_PO_ADD_VIEW = ["po-manager", "trupacta-team"];

export const ROLES_PO_VIEW = ["po-manager", "discrepancy-approver", "trupacta-team"];

export const ROLES_TRUPACTA_TEAM_VIEW = ["trupacta-team"];

export const ROLES_DISCREPANCY_APPROVER_VIEW = ["discrepancy-approver"];

export const ROLES_ORGANIZATION_VIEW = ["trupacta-team", "discrepancy-approver"];

export const ROLES_CONTRACTS_INVOICE_MANANGER = ["contract-invoice-manager"];

export const ROLES_HEADER_ORGANIZATION_VIEW = ["contract-invoice-manager", "po-manager", "discrepancy-approver", "invoice-manager", "contract-upload-manager"];

export const ROLES_INVOICE_MANAGER = ["invoice-manager", "trupacta-team"];

export const ROLES_CONTRACT_UPLOAD_MANAGER = ["contract-upload-manager", "trupacta-team"];

export const ROLES_REPORT_VIEW = ["super-admin"];

export const LOCAL_STORAGE_KEYS = {
  TOKEN: "token",
  USER: "user",
  ROLES: "roles",
  SELECTED_ORGANIZATION: "SELECTED_ORGANIZATION",
  SELECTED_VENDOR: "SELECTED_VENDOR",
  THEME: "THEME",
};

export const REBATES_FORM = [
  {
    "Tier Name": { label: "Tier Name", name: "tier_name", component: <Input /> },

    "Min Tier": { label: "Min Tier", name: "tier_min", component: <NumberFormat className="ant-input-number" thousandSeparator={true} allowNegative={false} prefix={"$"} /> },

    "Max Tier": { label: "Max Tier", name: "tier_max", component: <NumberFormat className="ant-input-number" thousandSeparator={true} allowNegative={false} prefix={"$"} /> },

    "Rebate Percentage %": {
      label: "Rebate Percentage %",
      name: "tier_rebate",
      component: <NumberFormat className="ant-input-number" thousandSeparator={true} allowNegative={false} decimalScale={2} suffix={"%"} maxLength={4} max={100} />,
    },
  },
];

// extract the tier data from the form
export const getTiersData = (inputObj) =>
  Object.keys(inputObj)
    .filter((key) => key.startsWith("tier_name"))
    .map((key) => {
      const index = key.replace("tier_name", "");
      return {
        tier_name: inputObj[`tier_name${index}`],
        tier_min: inputObj[`tier_min${index}`],
        tier_max: inputObj[`tier_max${index}`],
        tier_rebate: inputObj[`tier_rebate${index}`],
      };
    });
