import React, { useEffect, useState } from "react";
import { Button, Row, Col, Typography, Spin } from "antd";
import "./Contract.scss";
import Software from "../../assets/Icons/Software";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllTemplates,
  fetchContractsById,
  getSelectedContractTemplate,
} from "../../actions/Creators";
import history from "../../History/history";
import { withRouter } from "react-router-dom";
import { openPDF } from "../../utils/s3Upload";

const { Text } = Typography;

const SelectContractTemplate = ({ match }) => {
  const { contractId } = match.params;
  const dispatch = useDispatch();
  const templates = useSelector((state) => state.templatesReducer.templates);
  const loader = useSelector((state) => state.commonReducer.loader);
  const contract = useSelector((state) => state.contract.contract);
  const fileUrl = useSelector((state) => state.contract.contractFileUrl);
  const [selectedTemplate, setSetSelectedTemplate] = useState("");
  const [templateUrl, setTemplateUrl] = useState("");
  const getAllTemplates = () => {
    dispatch(fetchAllTemplates());
  };
  useEffect(() => {
    getAllTemplates();
    dispatch(fetchContractsById(contractId));
  }, []);
  const onSelect = () => {
    dispatch(getSelectedContractTemplate(contract.document.key));
  };
  useEffect(async () => {
    if (fileUrl) {
      await openPDF(fileUrl);
      history.push(`/contract/${contractId}/template/${selectedTemplate}`);
    }
  }, [fileUrl]);

  return (
    <Row justify="center" align="middle" className="select-contract-wrapper">
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Spin spinning={loader}>
          <h2>Select template for the contract category</h2>
          <div className="select-contract-inner-wrapper">
            {templates &&
              templates
                .sort((a, b) => a.orderNo - b.orderNo)
                .map((d) => {
                  return (
                    <div
                      className={`btn-template-box ${
                        d._id == selectedTemplate ? "active" : ""
                      } ${d.isDisabled ? "disable" : ""}`}
                      key={d._id}
                      onClick={(e) =>
                        d.isDisabled ? null : setSetSelectedTemplate(d._id)
                      }
                    >
                      {!d.isDisabled && (
                        <span className={`icon-select-tick`}></span>
                      )}
                      <Software />
                      <Text className="label-template-type">{d.name}</Text>
                    </div>
                  );
                })}
          </div>
          <div className="row-btn-request">
            <a href="#">Request Template</a>
          </div>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Button
                disabled={selectedTemplate ? false : true}
                className="btn-primary"
                onClick={onSelect}
              >
                <span className="btn-icon icon-arrow-square-right"></span>{" "}
                Proceed
              </Button>
            </Col>
          </Row>
        </Spin>
      </Col>
    </Row>
  );
};

export default withRouter(SelectContractTemplate);
