import { act } from "react-dom/test-utils";
import * as ActionTypes from "../actions/Types";

const initialState = {
  comments: [],
  commentsReasons: []
};

export const commentsHistory = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_COMMENTS:
      return { ...state, comments: action.payload.data };
    case ActionTypes.GET_COMMENT_REASONS:
      return { ...state, commentsReasons: action.payload.data}
    default:
      return state;
  }
};
