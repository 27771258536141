import * as ActionTypes from "../actions/Types";

const initialState = {
  contracts: [],
  count: 0,
  contract: {},
  createdTemplatedContract: {},
  contractFileUrl: "",
};

export const contract = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.VIEW_CONTRACTS:
      return {
        ...state,
        contracts: action.payload.rows,
        count: action.payload.count,
      };
    case ActionTypes.DELETE_CONTRACT:
      return { ...state, count: state.count - 1 };
    case ActionTypes.GET_CONTRACTS_BY_ID:
      return { ...state, contract: action.payload };
    case ActionTypes.CREATE_TEMPLATED_CONTRACT:
      return {
        ...state,
        createdTemplatedContract: action.payload,
      };
    case ActionTypes.UPDATE_CONTRACT:
      return {
        ...state,
        contract: action.payload,
      };
    case ActionTypes.GET_SELECTED_CONTRACT_TEMPLATE_FILE:
      return {
        ...state,
        contractFileUrl: action.payload,
      };
    case ActionTypes.CLEAR_SELECTED_CONTRACT_TEMPLATE_FILE:
      return {
        ...state,
        contractFileUrl: "",
      };
    case ActionTypes.CLEAR_FILE_URL:
      return {
        ...state,
        contractFileUrl: "",
      };
    case ActionTypes.GET_CONTRACT_FILE:
      return {
        ...state,
        associatedContractFile: action.payload,
      };
    case ActionTypes.CLEAR_CONTRACT_FILE:
      return {
        ...state,
        associatedContractFile: "",
      };
    default:
      return state;
  }
};
