import HttpRequest from "../../utils/httpRequests";
import endPoints from "../../utils/endPoints";

export const createContractss = (data) => {
  return HttpRequest("post", endPoints.CREATE_CONTRACTS, data);
};

export const getContractList = (org_id, params) => {
  return HttpRequest(
    "get",
    `${endPoints.GET_CONTRACTS}${org_id}`,
    null,
    null,
    params
  );
};

export const removeContract = (id) => {
  return HttpRequest("delete", `${endPoints.DELET_CONTRACTS}/${id}`);
};

export const getContractById = (id) =>
  HttpRequest("get", `${endPoints.GET_CONTRACTS_BY_ID}/${id}`);

export const createTemplatedContractAPI = (data) =>
  HttpRequest("post", endPoints.CREATE_TEMPLATED_CONTRACTS, data);

export const updateContractAPI = (id, data) =>
  HttpRequest("put", `${endPoints.GET_CONTRACTS_BY_ID}/${id}`, data);

export const updateTemplatedContractAPI = (id, data) => {
  return HttpRequest(
    "put",
    `${endPoints.UPDATE_TEMPLATED_CONTRACT}/${id}`,
    data
  );
};

export const getSelectedContractTemplateAPI = (key) =>
  HttpRequest("get", `${endPoints.GET_SELECTED_TEMPLATE_FILE}/${key}`);
