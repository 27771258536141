import React, { useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  Typography,
  Form,
  Input,
  Checkbox,
  Button,
  DatePicker,
  TimePicker,
  Row,
  Col,
} from "antd";
import Notification from "../../Common/Notification";
import { requestAppDemo, actionDispatch } from "../../actions/Creators";
import { useDispatch, useSelector } from "react-redux";
import SidePanel from "../Auth/SidePanel";
import { Link } from "react-router-dom";
import { HIDE_NOTIFICATION } from "../../actions/Types";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import moment from "moment";

const DemoForm = (props) => {
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.commonReducer.notification);
  const loading = useSelector((state) => state.commonReducer.loader);
  const [phoneNo, setPhoneNo] = useState({ country: "", phone: "" });
  const { Title } = Typography;
  const [form] = Form.useForm();

  const onSubmit = async (e) => {
    const preferences = [];
    if (
      form.getFieldsValue().firstPreferenceDate &&
      form.getFieldsValue().firstPreferenceTime
    ) {
      preferences.push({
        date: moment(form.getFieldsValue().firstPreferenceDate).format(
          "YYYY-MM-DD"
        ),
        startTime: moment(form.getFieldsValue().firstPreferenceTime[0]).format(
          "h:mm a"
        ),
        endTime: moment(form.getFieldsValue().firstPreferenceTime[1]).format(
          "h:mm a"
        ),
      });
    }
    if (
      form.getFieldsValue().secondPreferenceDate &&
      form.getFieldsValue().secondPreferenceTime
    ) {
      preferences.push({
        date: moment(form.getFieldsValue().secondPreferenceDate).format(
          "YYYY-MM-DD"
        ),
        startTime: moment(form.getFieldsValue().secondPreferenceTime[0]).format(
          "h:mm a"
        ),
        endTime: moment(form.getFieldsValue().secondPreferenceTime[1]).format(
          "h:mm a"
        ),
      });
    }
    dispatch(
      requestAppDemo({
        ...form.getFieldsValue(),
        preferences: preferences,
        phoneNo: phoneNo,
      })
    );
  };

  const options = [
    { label: "Email", value: "Email" },
    { label: "Phone", value: "Phone" },
    { label: "Text", value: "Text" },
  ];

  return (
    <SidePanel>
      <div className="login-form-wrapper">
        <Row align="middle" justify="center">
          <Col xxl={24} className="request-header">
            <Link to="/login" className="btn-request-back">
              <ArrowLeftOutlined />
            </Link>
            <Title level={2}>Request Demo</Title>
          </Col>
        </Row>

        <Form
          form={form}
          layout="vertical"
          onFinish={onSubmit}
          className="cc-form-group"
          validateTrigger="onBlur"
        >
          <Row align="middle" gutter={[16, 16]} justify="center">
            <Col lg={24} xl={12} xxl={12}>
              <Form.Item
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "First name required",
                  },
                ]}
              >
                <Input placeholder="Enter First name" />
              </Form.Item>
            </Col>

            <Col lg={24} xl={12} xxl={12}>
              <Form.Item
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Last name required",
                  },
                ]}
              >
                <Input placeholder="Enter Last name" />
              </Form.Item>
            </Col>
          </Row>

          <Row align="middle" justify="center" gutter={[16, 16]}>
            <Col lg={24} xl={24} xxl={24}>
              <Form.Item
                name="organization"
                rules={[
                  {
                    required: true,
                    message: "Name of Organization required",
                  },
                ]}
              >
                <Input placeholder="Enter your organization" />
              </Form.Item>
            </Col>
          </Row>

          <Row align="middle" gutter={[16, 16]} justify="center">
            <Col lg={24} xl={12} xxl={12}>
              <Form.Item
                name="phone_no"
                rules={[
                  {
                    required: true,
                    message: "Phone number required",
                  },
                ]}
              >
                <PhoneInput
                  inputClass="ant-input"
                  inputStyle={{
                    height: "36px",
                    width: "268px",
                  }}
                  country={"us"}
                  placeholder="Enter phone no."
                  onChange={(value, country, e, formattedValue) =>
                    setPhoneNo({
                      country: country.name,
                      phone: formattedValue,
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col lg={24} xl={12} xxl={12}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Email address required",
                  },
                ]}
              >
                <Input placeholder="Enter email" />
              </Form.Item>
            </Col>
          </Row>

          <Row align="middle" gutter={[16, 16]} justify="center">
            <Col lg={24} xl={24} xxl={24}>
              <Form.Item
                label="Method of communication"
                name="methodOfCommunication"
                rules={[
                  {
                    required: true,
                    message: "At least one field required",
                  },
                ]}
              >
                <Checkbox.Group options={options} />
              </Form.Item>
            </Col>
          </Row>

          <Row align="middle" gutter={[16, 16]} justify="center">
          <Col lg={24} xl={10} xxl={10}>
              <Form.Item name="firstPreferenceDate">
                <DatePicker />
              </Form.Item>
            </Col>
            <Col lg={24} xl={14} xxl={14}>
              <Form.Item name="firstPreferenceTime">
                <TimePicker.RangePicker use12Hours format="h:mm a" />
              </Form.Item>
            </Col>
          </Row>

          <Row align="middle" gutter={[16, 16]} justify="center">
            <Col lg={24} xl={10} xxl={10}>
              <Form.Item name="secondPreferenceDate">
                <DatePicker />
              </Form.Item>
            </Col>
            <Col lg={24} xl={14} xxl={14}>
              <Form.Item name="secondPreferenceTime">
                <TimePicker.RangePicker use12Hours format="h:mm a" />
              </Form.Item>
            </Col>
          </Row>

          <Row align="middle" gutter={[16, 16]} justify="center">
            <Col lg={24} xl={24} xxl={24}>
              <Form.Item name="comments">
                <Input.TextArea
                  placeholder="Comments"
                  rows={2}
                  maxLength={700}
                  showCount
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button
              className="form_button btn-login"
              block
              htmlType="submit"
              style={{ marginTop: "10px" }}
              loading={loading}
            >
              Submit
            </Button>
          </Form.Item>
          {notification.open && notification.message ? (
            <Notification
              msg={notification.message}
              type={notification.type}
              clearMsg={() => {
                dispatch(actionDispatch(HIDE_NOTIFICATION));
              }}
            />
          ) : null}
        </Form>
      </div>
    </SidePanel>
  );
};

export default DemoForm;
