import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Card, Badge, Row, Col } from "antd";
import { Treemap } from "@ant-design/charts";
import Loader from "../Loader";
import Information from "../../assets/images/information-icon.svg";

const TreeMap = ({ labelText = "", chartData }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (chartData) {
      const modifiedData = chartData.map((item) => ({
        ...item,
        name: `${item?.name}-qty-${item?.quantity}`, // doing, because the tooltip doesnt support other than name/value key, so adding the quantity here and will modify below at the label formatter
        value: item.value || 1, // If value is null, use 0
      }));
      setData(modifiedData);
    }
  }, [chartData]);

  const truncateLabel = (label) => {
    return label.length > 6 ? label.substring(0, 6) + ".." : label;
  };

  const config = {
    data: {
      name: "root",
      children: data,
    },
    colorField: "name",
    color: ["#addcde", "#83cbcd", "#5ababd", "#31a8ac", "#08979c"],
    legend: false,
    height: 300,
    tooltip: {
      formatter: (v, data, index, col) => {
        return {
          name: `Name: ${v.name.split("-qty-")[0]}`,
          value: `Quantity: ${v?.name.split("-qty-")[1]} Amount: $${Math.round(v?.value)}`,
        };
      },
    },
    interactions: [{ type: "element-active" }],
    label: {
      layout: {
        type: "limit-in-shape",
      },
      formatter: (datum) => {
        return truncateLabel(datum?.name.split("-qty-")[0]); // Modify label content
      },
    },
  };

  return (
    <Card className="dashboad-chart-box dashboard-card-t1 ">
      <Row className="mb-1">
        <Col xs={12} sm={12} md={24} lg={24} xl={24} xxl={24}>
          <h3>
            {labelText} <img src={Information} alt="information-icon" />{" "}
          </h3>
        </Col>
      </Row>
      {chartData && !_.isEmpty(data) ? <Treemap {...config} /> : <Loader />}
    </Card>
  );
};

export default TreeMap;
