import {
  getContractList,
  createContractss,
  removeContract,
  getContractById,
  createTemplatedContractAPI,
  updateContractAPI,
  updateTemplatedContractAPI,
  getSelectedContractTemplateAPI,
} from "../../Services/Contracts/contracts";
import {
  getContractFileAPI,
  uploadContractDocs,
} from "../../Services/Documents/document";
import {
  HIDE_LOADER,
  SHOW_LOADER,
  SHOW_NOTIFICATION,
  VIEW_CONTRACTS,
  GET_CONTRACTS_BY_ID,
  CREATE_TEMPLATED_CONTRACT,
  DELETE_CONTRACT,
  UPDATE_CONTRACT,
  UPDATE_TEMPLATED_CONTRACT,
  GET_SELECTED_CONTRACT_TEMPLATE_FILE,
  CLEAR_SELECTED_CONTRACT_TEMPLATE_FILE,
  CLEAR_FILE_URL,
  GET_CONTRACT_FILE,
  CLEAR_CONTRACT_FILE,
  SHOW_FILE_LOADER,
  HIDE_FILE_LOADER
} from "../Types";
import { actionDispatch, triggerNotification } from "./";
import { uploadToS3 } from "../../utils/s3Upload";
import history from "../../History/history";
import { VIEW_CONTRACTS_ROUTE } from "../../Constants/routeNames";
import {
  UNPERFORMED_GET_ACTION,
  UNPERFORMED_SAVE_ACTION,
  CONTRACTS_SAVED_SUCCESSFULLY,
} from "../../Constants/validation";

export const createContracts = (data, userId) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    //create contracts and get signedUrl in response
    const res = await createContractss(data);
    const temp = [];
    //Loop over array and upload each contract on url
    res.data.forEach(async (contract, i) => {
      await uploadToS3(contract.signedUrl, data[i].originFileObj);
      temp.push({
        contract_id: contract.id,
        user_id: userId,
        type: "contract",
        key: contract.file_key,
        name: data[i].name,
      });
      //creating docs after successfully uploaded files
      if (temp.length == res.data.length) {
        const uploadRes = await uploadContractDocs(temp);
        if (uploadRes.success) {
          dispatch(actionDispatch(HIDE_LOADER));
          history.push(`${VIEW_CONTRACTS_ROUTE}`);
        }
        dispatch(
          actionDispatch(SHOW_NOTIFICATION, {
            message: CONTRACTS_SAVED_SUCCESSFULLY,
            open: true,
            type: "success",
          })
        );
      }
    });
    //Hit Document API
  } catch (ex) {
    console.log(ex);
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message:
          ex.response &&
          ex.response.data &&
          typeof ex.response.data.message == "string"
            ? ex.response.data.message
            : UNPERFORMED_SAVE_ACTION,
        open: true,
        type: "error",
      })
    );
  }
};

export const getContracts = (org_id, params) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getContractList(org_id, params);
    dispatch(actionDispatch(VIEW_CONTRACTS, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message:
          ex.response && ex.response.data
            ? ex.response.data.message
            : UNPERFORMED_GET_ACTION,
        open: true,
        type: "error",
      })
    );
  }
};

export const deleteContracts = (id, existingData) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const response = await removeContract(id);
    dispatch(actionDispatch(DELETE_CONTRACT, id));
    if (response.success) {
      dispatch(actionDispatch(HIDE_LOADER));
      dispatch(
        actionDispatch(SHOW_NOTIFICATION, {
          message: response.message,
          open: true,
          type: "success",
        })
      );
    }
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message: ex.response.data.message,
        open: true,
        type: "error",
      })
    );
  }
};

export const fetchContractsById = (id) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const response = await getContractById(id);
    dispatch(actionDispatch(GET_CONTRACTS_BY_ID, response.data));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message:
          ex.response && ex.response.data
            ? ex.response.data.message
            : UNPERFORMED_GET_ACTION,
        open: true,
        type: "error",
      })
    );
  }
};

export const createTemplatedContract = (data) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const response = await createTemplatedContractAPI(data);
    dispatch(actionDispatch(CREATE_TEMPLATED_CONTRACT, response.data));
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message: "Templated contract created successfully",
        open: true,
        type: "success",
      })
    );
    history.push("/contracts/view");
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER));
    console.log(ex);
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message:
          ex.response && ex.response.data
            ? ex.response.data.message
            : UNPERFORMED_GET_ACTION,
        open: true,
        type: "error",
      })
    );
  }
};

export const updateContract = (id, data) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const response = await updateContractAPI(id, data);
    dispatch(actionDispatch(UPDATE_CONTRACT, response.data));
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message: "Contract updated successfully",
        open: true,
        type: "success",
      })
    );
    history.push("/contracts/view");
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER));
    console.log(ex);
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message:
          ex.response && ex.response.data
            ? ex.response.data.message
            : UNPERFORMED_GET_ACTION,
        open: true,
        type: "error",
      })
    );
  }
};

export const updateTemplatedContract = (id, data) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const response = await updateTemplatedContractAPI(id, data);
    dispatch(actionDispatch(UPDATE_TEMPLATED_CONTRACT, response.data));
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message: "Contract updated successfully",
        open: true,
        type: "success",
      })
    );
    history.push("/contracts/view");
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER));
    console.log(ex);
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message:
          ex.response && ex.response.data
            ? ex.response.data.message
            : UNPERFORMED_GET_ACTION,
        open: true,
        type: "error",
      })
    );
  }
};

export const getSelectedContractTemplate = (key) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  dispatch(actionDispatch(SHOW_FILE_LOADER));
  try {
    const response = await getSelectedContractTemplateAPI(key);
    dispatch(
      actionDispatch(GET_SELECTED_CONTRACT_TEMPLATE_FILE, response.data)
    );
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(actionDispatch(HIDE_FILE_LOADER));
  } catch (ex) {
    console.log(ex);
    dispatch(actionDispatch(HIDE_FILE_LOADER));
    dispatch(triggerNotification("error", "Something went wrong"));
  }
};
export const clearSelectedContractUrlFile = () => (dispatch) => {
  dispatch(actionDispatch(CLEAR_SELECTED_CONTRACT_TEMPLATE_FILE, {}));
};
export const emptyFileUrl = () => (dispatch) => {
  dispatch(actionDispatch(CLEAR_FILE_URL, {}));
};

export const getContractFile = (key) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  dispatch(actionDispatch(SHOW_FILE_LOADER))
  try {
    const res = await getContractFileAPI(key);
    dispatch(actionDispatch(GET_CONTRACT_FILE, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(actionDispatch(HIDE_FILE_LOADER));
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(actionDispatch(HIDE_FILE_LOADER));
    triggerNotification("error", ex);
  }
};

export const clearContractFile = () => (dispatch) => {
  dispatch(actionDispatch(CLEAR_CONTRACT_FILE));
};
