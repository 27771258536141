import {
  getInvoiceList,
  createNewInvoices,
  removeInvoice,
  getInvoice,
  updateInvoice,
  updateInvoiceAndInvoiceItems,
  getInvoiceListWithDisc,
  recalculateInvoiceDiscrepancy,
  updateInvoiceStatusWithComment,
  getInvoiceListForInvoiceManager,
} from "../../Services/Invoices/invoices";
import { uploadInvoiceDocs, getInvoiceFileAPI } from "../../Services/Documents/document";
import {
  DELETE_INVOICE,
  HIDE_LOADER,
  SHOW_LOADER,
  SHOW_NOTIFICATION,
  VIEW_INVOICES,
  VIEW_INVOICE,
  UPDATE_INVOICE,
  GET_INVOICE_FILE,
  CLEAR_INVOICE_FILE_URL,
  SHOW_FILE_LOADER,
  HIDE_FILE_LOADER
} from "../Types";
import { VIEW_INVOICES_ROUTE } from "../../Constants/routeNames";
import { actionDispatch, triggerNotification } from "./";
import { uploadToS3 } from "../../utils/s3Upload";
import {
  UNPERFORMED_GET_ACTION,
  UNPERFORMED_SAVE_ACTION,
  UNPERFORMED_UPDATE_ACTION,
  INVOICES_SAVED_SUCCESSFULLY,
} from "../../Constants/validation";
import history from "../../History/history";

export const createInvoices = (data, userId) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    //create invoice and get signedUrl in response
    const res = await createNewInvoices(data);
    const temp = [];
    //Loop over array and upload each invoice on url
    res.data.forEach(async (invoice, i) => {
      await uploadToS3(invoice.signedUrl, data[i].originFileObj);
      temp.push({
        invoice_id: invoice.id,
        invoice_number: invoice.invoice_number,
        user_id: userId,
        type: "invoice",
        key: invoice.file_key,
        name: data[i].name,
      });
      if (temp.length == res.data.length) {
        const uploadRes = await uploadInvoiceDocs(temp);
        if (uploadRes) {
          dispatch(actionDispatch(HIDE_LOADER));
          history.push(`${VIEW_INVOICES_ROUTE}`);
        }
        dispatch(
          actionDispatch(SHOW_NOTIFICATION, {
            message: INVOICES_SAVED_SUCCESSFULLY,
            open: true,
            type: "success",
          })
        );
      }
    });
    //Hit Document API
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message:
          ex.response &&
          ex.response.data &&
          typeof ex.response.data.message == "string"
            ? ex.response.data.message
            : UNPERFORMED_SAVE_ACTION,
        open: true,
        type: "error",
      })
    );
  }
};

export const getInvoices =
  (org_id, params, isApprover = false) =>
  async (dispatch) => {
    dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
    try {
      let res;
      if (isApprover) {
        res = await getInvoiceListForInvoiceManager(org_id, params);
      } else {
        res = await getInvoiceList(org_id, params);
      }
      dispatch(actionDispatch(VIEW_INVOICES, res.data));
      dispatch(actionDispatch(HIDE_LOADER));
    } catch (ex) {
      dispatch(actionDispatch(HIDE_LOADER));
      dispatch(
        actionDispatch(SHOW_NOTIFICATION, {
          message:
            ex.response && ex.response.data
              ? ex.response.data.message
              : UNPERFORMED_GET_ACTION,
          open: true,
          type: "error",
        })
      );
    }
  };

export const getInvoiceById = (id) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getInvoice(id);
    if (res.success) {
      dispatch(actionDispatch(VIEW_INVOICE, res.data));
      dispatch(actionDispatch(HIDE_LOADER));
    }
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message:
          ex.response && ex.response.data
            ? ex.response.data.message
            : UNPERFORMED_GET_ACTION,
        open: true,
        type: "error",
      })
    );
  }
};

export const updateInvoiceById = (id, data) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const response = await updateInvoice(id, data);
    dispatch(actionDispatch(UPDATE_INVOICE, data));
    if (response && response.success) {
      dispatch(actionDispatch(HIDE_LOADER));
      dispatch(
        actionDispatch(SHOW_NOTIFICATION, {
          message: response && response.message,
          open: true,
          type: "success",
        })
      );
      history.push(`${VIEW_INVOICES_ROUTE}`);
    }
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message:
          ex.response && ex.response.data
            ? ex.response.data.message
            : UNPERFORMED_UPDATE_ACTION,
        open: true,
        type: "error",
      })
    );
  }
};

export const deleteInvoice = (id, existingData) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const response = await removeInvoice(id);
    dispatch(actionDispatch(DELETE_INVOICE, id));
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message: response.message,
        open: true,
        type: "success",
      })
    );
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message: ex.response.data.message,
        open: true,
        type: "error",
      })
    );
  }
};

export const getRecalculatedValuesForInvoice =
  (id, data) => async (dispatch) => {
    dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
    try {
      const res = await recalculateInvoiceDiscrepancy(id);
      dispatch(actionDispatch(VIEW_INVOICE, res.data));
      if (res && res.success) {
        dispatch(actionDispatch(HIDE_LOADER));
        // history.push(`${VIEW_PO_ROUTE}`);
      }
    } catch (ex) {
      dispatch(actionDispatch(HIDE_LOADER));
      dispatch(
        actionDispatch(SHOW_NOTIFICATION, {
          message:
            ex.response && ex.response.data
              ? ex.response.data.message
              : UNPERFORMED_GET_ACTION,
          open: true,
          type: "error",
        })
      );
    }
  };

export const updateInvoiceAndItemsById =
  (id, data, historyData, shouldRecheck) => async (dispatch) => {
    dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
    try {
      const response = await updateInvoiceAndInvoiceItems(id, data);
      if (response.data.isPoValid || response.data.invoice_wo_po) {
        await updateInvoiceStatusWithComment(id, historyData);
        //If user confirms Invoice recheck discrepancies and send reRun value as true
        const recheckDisc =
          shouldRecheck &&
          (await recalculateInvoiceDiscrepancy(id, { reRun: true }));
        if (shouldRecheck && recheckDisc.success) {
          dispatch(actionDispatch(HIDE_LOADER));
          dispatch(
            actionDispatch(SHOW_NOTIFICATION, {
              message: response && response.message,
              open: true,
              type: "success",
            })
          );
          history.push(`${VIEW_INVOICES_ROUTE}`);
        }
        //Otherwise simply update and send back response
        else if (!shouldRecheck && response && response.success) {
          dispatch(actionDispatch(HIDE_LOADER));
          dispatch(
            actionDispatch(SHOW_NOTIFICATION, {
              message: response && response.message,
              open: true,
              type: "success",
            })
          );
          history.push(`${VIEW_INVOICES_ROUTE}`);
        }
      } else {
        dispatch(actionDispatch(VIEW_INVOICE, response.data));
        dispatch(actionDispatch(HIDE_LOADER));
        dispatch(
          triggerNotification(
            "success",
            "Invoice updated successfully but associated PO not found."
          )
        );
      }
    } catch (ex) {
      dispatch(actionDispatch(HIDE_LOADER));
      dispatch(
        actionDispatch(SHOW_NOTIFICATION, {
          message:
            ex.response && ex.response.data
              ? ex.response.data.message
              : UNPERFORMED_UPDATE_ACTION,
          open: true,
          type: "error",
        })
      );
    }
  };

  export const getInvoiceFile = (key) => async (dispatch) => {
    dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
    dispatch(actionDispatch(SHOW_FILE_LOADER));
    try {
      const res = await getInvoiceFileAPI(key);
      dispatch(actionDispatch(GET_INVOICE_FILE, res.data))
      dispatch(actionDispatch(HIDE_LOADER));
      dispatch(actionDispatch(HIDE_FILE_LOADER));
    } catch (ex) {
      dispatch(actionDispatch(HIDE_FILE_LOADER));
      dispatch(actionDispatch(HIDE_LOADER));
      triggerNotification("error", ex);
    }
  };

  export const clearInvoiceFile = () => dispatch => {
    dispatch(actionDispatch(CLEAR_INVOICE_FILE_URL, {}));
  }