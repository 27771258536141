import { updateUserInfo, getUsers, getUsersOfOrg } from "../../Services/User/user"
import {
  HIDE_LOADER,
  SHOW_LOADER,
  SHOW_NOTIFICATION,
  GET_USER,
  UPDATE_USER,
  GET_USERS
} from "../Types";
import { actionDispatch } from "./";
import { getUserInfo } from "../../Services/User/user";
import history from "../../History/history";
import { saveToLocal, getFromLocal } from "../../utils/cache";
import { LOCAL_STORAGE_KEYS } from "../../utils/constants";

export const getUser = () => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getUserInfo();
    dispatch(actionDispatch(GET_USER, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    console.log(ex);
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message: ex.response.data && ex.response.data.message,
        open: true,
        type: "error",
      })
    );
  }
};

export const updateUser = (data) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await updateUserInfo(data);
    const user = getFromLocal(LOCAL_STORAGE_KEYS.USER, true, true);
    user.first_name= res.data.first_name;
    user.last_name= res.data.last_name;
    saveToLocal(user, LOCAL_STORAGE_KEYS.USER, true, true);
    dispatch(actionDispatch(UPDATE_USER, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message: res.message,
        open: true,
        type: "success",
      })
    );
    history.push("/");
  } catch (ex) {
    console.log(ex);
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message:  ex.response.data && ex.response.data.message,
        open: true,
        type: "error",
      })
    );
  }
};

export const getAllUsers = (org_id) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = org_id ? await getUsersOfOrg(org_id): await getUsers();
    dispatch(actionDispatch(GET_USERS, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    console.log(ex);
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message:  ex.response.data && ex.response.data.message,
        open: true,
        type: "error",
      })
    );
  }
};


