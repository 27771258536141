import moment from "moment";
import _ from "lodash";

const getMonthValues = (data) => {
  const monthValues =
    data &&
    data.map((data) => {
      const { createdAt } = data;
      const month = moment(createdAt).format("MMM");
      return { amount: data.amount, month };
    });
  return monthValues;
};

const getMonthValuesForProcessedContracts = (data) => {
  const monthValues =
    data &&
    data.map((data) => {
      const { createdAt } = data;
      const month = moment(createdAt).format("MMM");
      return { processed_time: data.contractProcessedTime, month };
    });
  return monthValues;
};

const getApprovedRejectedAggregate = (data) => {
  const monthValues =
    data &&
    data.map((data) => {
      const { createdAt } = data;
      const month = moment(createdAt).format("MMM");
      return { approved: data.approved, rejected: data.rejected, month };
    });
  return monthValues;
};

const getAllYearMonths = () => {
  let resultDates = [];
  let current = moment();
  let n = 12;
  while (n > 0) {
    resultDates.push({ month: current.format("MMM"), amount: 0, number: 0, totalProcessedTime: 0 });
    current.subtract(1, "months");
    n--;
  }
  resultDates.reverse();
  return resultDates;
};

const sumOfArrayAmountVals = (arr) => {
  let sum = 0;
  for (let i in arr) {
    sum += arr[i].amount;
  }
  return sum;
};

const sumOfArrayProcessedTimeVals = (arr) => {
  let sum = 0;
  for (let i in arr) {
    sum += arr[i].processed_time;
  }
  return sum;
};

const sumOfArrayVals = (arr) => {
  let sum = 0;
  for (let i in arr) {
    sum += arr[i];
  }
  return sum;
};

export const getFormatDataForCostSaving = (data, formatBy = "month") => {
  const monthValues = getMonthValues(data);
  const resultDates = getAllYearMonths();
  const groupedData = _.chain(monthValues)
    .groupBy(formatBy)
    .map((value, key) => ({
      month: key,
      amount: sumOfArrayAmountVals(value),
      name: "Amount",
    }))
    .value();

  //Show all months irrespective of zero records
  resultDates.forEach((r) => {
    groupedData.forEach((group) => {
      if (r.month === group.month) {
        r.month = group.month;
        r.amount = group.amount;
      }
      r.name = "Amount($)";
    });
  });
  return resultDates;
};

export const getFormatDataForAggregatePercentage = (
  data,
  formatBy = "month"
) => {
  const monthValues = getApprovedRejectedAggregate(data);
  const resultDates = getAllYearMonths();
  const groupedData = _.chain(monthValues)
    .groupBy(formatBy)
    .map((value, key) => ({ month: key, data: value }))
    .value();
  //Show all months irrespective of zero records
  resultDates.forEach((r) => {
    groupedData.forEach((group) => {
      if (r.month === group.month) {
        r.month = group.month;
        r.data = group.data;
      }
      r.name = "Number";
    });
  });
  let finalData = [];
  resultDates.forEach((group) => {
    const approved = group && group.data && group.data.map((g) => g.approved);
    const rejected = group && group.data && group.data.map((g) => g.rejected);
    finalData = [
      ...finalData,
      {
        month: group.month,
        name: "Approved",
        number: sumOfArrayVals(approved),
      },
      {
        month: group.month,
        name: "Rejected",
        number: sumOfArrayVals(rejected),
      },
    ];
  });
  return finalData;
};

export const getFormatDataForNumberCount = (data, formatBy = "month") => {
  const monthValues = getMonthValues(data);
  const resultDates = getAllYearMonths();
  const groupedData = _.chain(monthValues)
    .groupBy(formatBy)
    .map((value, key) => ({ month: key, number: value.length }))
    .value();
  //Show all months irrespective of zero records
  resultDates.forEach((r) => {
    groupedData.forEach((group) => {
      if (r.month === group.month) {
        r.month = group.month;
        r.number = group.number;
      }
      r.name = "Number";
    });
  });
  return resultDates;
};

export const getFormatDataForSumofTime = (data, formatBy = "month") => {
  const monthValues = getMonthValuesForProcessedContracts(data);
  const resultDates = getAllYearMonths();
  const groupedData = _.chain(monthValues)
    .groupBy(formatBy)
    .map((value, key) => ({ month: key, totalProcessedTime: sumOfArrayProcessedTimeVals(value) }))
    .value();

  //Show all months irrespective of zero records
  resultDates.forEach((r) => {
    groupedData.forEach((group) => {
      if (r.month === group.month) {
        r.month = group.month;
        r.totalProcessedTime = group.totalProcessedTime;
      }
      r.name = "totalProcessedTime";
    });
  });
  return resultDates;
};


export const defaultCounterFunc = (report, countTitle = "") => {
  return {
    [countTitle]: report && report.count,
  };
};

export const getApprovedRejectedAvg = (report) => {
  let approvedDocs = 0;
  let rejectedDocs = 0;
  let total = 0;
  report &&
    report.rows &&
    report.rows.forEach((row) => {
      approvedDocs += row.approved;
      rejectedDocs += row.rejected;
      total += parseInt(row.total);
    });
  return {
    Documents: total,
    "Approved Documents": approvedDocs !== 0 ? ((approvedDocs / total) * 100).toFixed(2) + "%" : 0 + "%",
    "Rejected Documents": rejectedDocs !==0 ? ((rejectedDocs / total) * 100).toFixed(2) + "%" : 0 + "%",
  };
};

export const getCounterFieldsForPOAggregate = (report) => {
  return {
    "Discrepancy Amount":
      report &&
      report.amount &&
      `$${Math.round(report.amount)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
    "No. of Discrepancies": report && report.count,
  };
};

export const getCounterFieldsForInvoiceAggregate = (report) => {
  return {
    "Discrepancy Amount":
      report &&
      report.amount &&
      `$${Math.round(report.amount)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
      "No. of Discrepancies": report && report.rows && report.rows.length,
  };
};

export const convertToInternationalCurrencySystem = (labelValue) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + 'B'
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + 'M'
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + 'K'
    : Math.abs(Number(labelValue));
};
