import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import moment from "moment";
import {
  Row,
  Col,
  Table,
  Input,
  Button,
  Form,
  Avatar,
  DatePicker,
  Switch,
  Tooltip,
  InputNumber,
  Typography,
  Spin,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import { UPLOAD_PO_ROUTE } from "../../Constants/routeNames";
import { DeleteOutlined } from "@ant-design/icons";
import {
  actionDispatch,
  getVendors,
  createPoManually,
  triggerNotification,
} from "../../actions/Creators";
import Loader from "../../Common/Loader";
import history from "../../History/history";
import Notification from "../../Common/Notification";
import VendorDropdown from "../../Common/VendorDropdown";
import { HIDE_NOTIFICATION } from "../../actions/Types";
import { Status } from "./POStatus";
import dummyVendorLogo from "../../assets/images/abc-aus.jpg";
import { isEmpty } from "../../utils/isEmpty";
import Authorized from "../../Role-Authorization/Authorized";
import { LOCAL_STORAGE_KEYS, ROLES_PO_ADD_VIEW } from "../../utils/constants";
import { getFromLocal } from "../../utils/cache";

const discrepancyText = "Contractual";

export default () => {
  const [form] = Form.useForm();
  const [tableForm] = Form.useForm();
  const dispatch = useDispatch();
  const [selectedVendor, setSelectedVendor] = useState({});
  const [totalAmount, setTotalAmount] = useState(0);
  const vendor = useSelector((state) => state.vendor);
  const notification = useSelector((state) => state.commonReducer.notification);
  const po = useSelector((state) => state.po.po);
  const loading = useSelector((state) => state.commonReducer.loader);
  const organization = useSelector(
    (state) => state.header.selectedOrganization
  );
  const [tableData, setTableData] = useState([{}]);
  const org = getFromLocal(
    LOCAL_STORAGE_KEYS.SELECTED_ORGANIZATION,
    true,
    true
  );
  const { Text } = Typography;

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      align: "center",
      render: (text, object, index) => index + 1,
    },
    {
      title: "SKU Number",
      dataIndex: "sku_numbeer",
      key: "sku_number",
      render: (text, record, index) => (
        <Form.Item
          name={`${"sku_number"}-${index}`}
          rules={[
            {
              required: true,
              message: "Please provide the SKU Number",
            },
          ]}
        >
          <Input />
        </Form.Item>
      ),
    },
    {
      title: "SKU Description",
      dataIndex: "item_name",
      key: "item_name",
      render: (text, record, index) => (
        <Form.Item name={`${"item_name"}-${index}`}>
          <Input />
        </Form.Item>
      ),
    },
    // {
    //   title: "Subscriptions",
    //   dataIndex: "subscription",
    //   key: "subscriptions",
    //   render: (subscription, record, index) => (
    //     <div
    //       style={{
    //         display: "flex",
    //         justifyContent: "space-between",
    //       }}
    //     >
    //       <Form.Item name={`${"subscription"}-${index}`}>
    //         <InputNumber />
    //       </Form.Item>
    //       {record.discrepancies &&
    //         record.discrepancies.data &&
    //         record.discrepancies.data.map((data) => {
    //           if (data.key === "subscription")
    //             return (
    //               <Tooltip
    //                 title={`${discrepancyText} ${data.key} = ${data.contract_value}`}
    //                 color="#f87b57"
    //               >
    //                 <span class="icon-warning"></span>
    //               </Tooltip>
    //             );
    //         })}
    //     </div>
    //   ),
    // },
    // {
    //   title: "Users Per Subscription",
    //   dataIndex: "users_per_subscription",
    //   key: "usersPerSubscription",
    //   render: (text, record, index) => (
    //     <div
    //       style={{
    //         display: "flex",
    //         justifyContent: "space-between",
    //       }}
    //     >
    //       <Form.Item name={`${"users_per_subscription"}-${index}`}>
    //         <InputNumber />
    //       </Form.Item>
    //       {record.discrepancies &&
    //         record.discrepancies.data &&
    //         record.discrepancies.data.map((data) => {
    //           if (data.key === "users_per_subscription")
    //             return (
    //               <Tooltip
    //                 title={`${discrepancyText} ${data.key} = ${data.contract_value}`}
    //                 color="#f87b57"
    //               >
    //                 <span class="icon-warning"></span>
    //               </Tooltip>
    //             );
    //         })}
    //     </div>
    //   ),
    // },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record, index) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Form.Item
            name={`${"quantity"}-${index}`}
            rules={[
              {
                required: true,
                message: "Please provide Quantity.",
              },
            ]}
          >
            <NumberFormat
              className="ant-input-number"
              thousandSeparator={true}
              allowNegative={false}
            />
          </Form.Item>
          {record.discrepancies &&
            record.discrepancies.data &&
            record.discrepancies.data.map((data) => {
              if (data.key === "quantity")
                return (
                  <Tooltip
                    title={`${discrepancyText} ${data.key} = ${data.contract_value}`}
                    color="#f87b57"
                  >
                    <span class="icon-warning"></span>
                  </Tooltip>
                );
            })}
        </div>
      ),
    },
    {
      title: "Net Price",
      dataIndex: "final_price",
      key: "finalPrice",
      render: (finalPrice, record, index) => (
        <div className="discrepancies-cell-wrapper">
          <Form.Item name={`${"final_price"}-${index}`}>
            <NumberFormat
              className="ant-input-number"
              thousandSeparator={true}
              prefix={"$"}
              decimalScale={2}
              fixedDecimalScale
              allowNegative={false}
            />
          </Form.Item>
          {record.discrepancies &&
            record.discrepancies.data &&
            record.discrepancies.data.map((data) => {
              if (data.key === "final_price")
                return (
                  <Tooltip
                    title={`${discrepancyText} ${data.key} = ${data.contract_value}`}
                    color="#f87b57"
                  >
                    <span class="icon-warning"></span>
                  </Tooltip>
                );
            })}
        </div>
      ),
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (total, record, index) => {
        return (
          <Form.Item name={`total-${index}`}>
            <NumberFormat
              className="ant-input-number"
              thousandSeparator={true}
              prefix={"$"}
              decimalScale={2}
              fixedDecimalScale
              allowNegative={false}
              onChange={setValueToTotalAmount}
            />
          </Form.Item>
        );
      },
    },
    {
      title: "",
      dataIndex: "actions",
      align: "center",
      width: "50px",
      render: (_, row, index) => {
        return (
          <Tooltip title="Delete">
            <DeleteOutlined
              style={{ cursor: "pointer" }}
              className="clr-link"
              onClick={() => deleteRow(row, index)}
            />
          </Tooltip>
        );
      },
    },
  ];
  useEffect(() => {
    if (_.isEmpty(vendor.vendors)) {
      dispatch(getVendors(org.id));
    }
  }, [tableData]);

  const sendForApproval = () => {
    let contractId, templatedContractId;
    po &&
      po.purchase_order_items &&
      po.purchase_order_items.forEach((poItem) => {
        if (poItem && !isEmpty(poItem.discrepancies)) {
          contractId = poItem.discrepancies.contract_id;
          templatedContractId = poItem.discrepancies.templated_contract_id;
        }
      });
    const data = {
      po_id: po.id,
      type: "PO_CONTRACT",
      contract_id: contractId,
      vendor_id: po.vendor_id,
      user_id: po.user_id,
      organization_id: organization.id,
      templated_contract_id: templatedContractId,
      status: Status.PENDING,
    };

    // dispatch(createDiscrepancyForPO(data));
  };

  const setValueToTotalAmount = () => {
    const updatedValues = tableForm.getFieldsValue();
    let maxRows = 0;
    let columnsList = Object.keys(updatedValues).map((dt) => {
      maxRows = dt.split("-")[1];
      return `${dt.split("-")[0]}`;
    });
    columnsList = [...new Set(columnsList)];
    const dto = [...Array(parseInt(maxRows) + 1).keys()].map((_, index) => {
      return columnsList.reduce((result, value, colIndex) => {
        result[value] = updatedValues[`${value}-${index}`];
        return result;
      }, {});
    });
    const temp = [];
    dto.forEach((i) => {
      i.total = convertToCurrencyNumber(i.total);
      if (i.total && !isNaN(i.total)) temp.push(Number(i.total));
      else temp.push(0);
    });

    let finalPrice = 0;
    temp.forEach((discountAmount) => {
      if (!isNaN(discountAmount) || discountAmount !== "") {
        finalPrice += discountAmount;
      }
    });
    setTotalAmount(convertToCurrencyNumber(finalPrice));
  };

  const onSubmit = async (values) => {
    values.vendor = {
      id: parseInt(selectedVendor.id),
      value: selectedVendor.value,
    };
    tableForm.submit();
    await tableForm.validateFields();
    const tableValues = tableForm.getFieldsValue();
    let maxRows = 0;
    let columnsList = Object.keys(tableValues).map((dt) => {
      maxRows = dt.split("-")[1];
      return `${dt.split("-")[0]}`;
    });
    columnsList = [...new Set(columnsList)];
    const dto = [...Array(parseInt(maxRows) + 1).keys()].map((_, index) => {
      return columnsList.reduce((result, value, colIndex) => {
        result[value] = tableValues[`${value}-${index}`];
        return result;
      }, {});
    });
    const duplications = [];
    dto.forEach((record, index) => {
      for (let prop in record) {
        if (prop == "sku_number") {
          duplications.push(record[prop]);
        }
      }
      record.final_price =
        record.final_price && convertToCurrencyNumber(record.final_price);
      record.quantity =
        record.quantity && convertToCurrencyNumber(record.quantity);
      record.total = record.total && convertToCurrencyNumber(record.total);
    });

    const checkDuplication = _.filter(duplications, (val, i, iteratee) => {
      return _.includes(iteratee, val, i + 1);
    });

    if (checkDuplication.length) {
      dispatch(
        triggerNotification("error", "SKU Number can not be duplicated")
      );
      return;
    }

    if (
      values.issue_date &&
      values.delivery_date &&
      values.issue_date.isAfter(values.delivery_date.format("YYYY-MM-DD"))
    ) {
      dispatch(
        triggerNotification(
          "error",
          "Delivery date should not be before the issue date."
        )
      );
      return;
    }
    values.delivery_date =
      values.delivery_date && moment(values.delivery_date).format("YYYY-MM-DD");
    values.issue_date = moment(values.issue_date).format("YYYY-MM-DD");
    values.organization_id = org.id;
    const dataToSend = {
      ...values,
      amount: totalAmount,
      sku: dto,
      status: "processed",
    };

    dispatch(createPoManually(dataToSend));
  };

  const addRow = () => {
    let data = tableData;
    data.push({
      item_name: "",
      subscription: "",
      users_per_subscription: "",
      vat: "",
      final_price: "",
      po_id: "",
    });
    setTableData([...data]);
  };
  const deleteRow = (row, index) => {
    let data = tableData;
    if (tableData.length < 2) {
      dispatch(triggerNotification("error", "At least 1 SKU must be included"));
      return;
    } else {
      let fieldValues = tableForm.getFieldsValue();
      data = data.filter((d, i) => {
        return i !== index;
      });
      tableForm.resetFields();
      setTableData([...data]);
      for (let prop in fieldValues) {
        delete fieldValues[`${prop.split("-")[0]}-${index}`];
      }
      let obj = {};
      let maxRows;
      let columnsList = Object.keys(fieldValues).map((dt) => {
        maxRows = dt.split("-")[1];
        return `${dt.split("-")[0]}`;
      });
      columnsList = [...new Set(columnsList)];
      const dto = [...Array(parseInt(maxRows) + 1).keys()].map((_, index1) => {
        return columnsList.reduce((result, value, colIndex) => {
          if (index1 !== index) {
            result[value] = fieldValues[`${value}-${index1}`];
            return result;
          }
        }, {});
      });

      let filtered = dto.filter((el) => {
        return el != null;
      });

      filtered.map((d, i) => {
        Object.keys(d).map((dt) => {
          obj[`${dt}-${i}`] = d[dt];
        });
      });
      tableForm.setFieldsValue({
        ...obj,
      });

      setValueToTotalAmount();
    }
  };

  const calcValueWithoutRounding = (numString) => {
    let numWith2Decimal = numString.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
    if (numWith2Decimal == "") return null;
    return Number(numWith2Decimal);
  };

  const convertToCurrencyNumber = (numString) => {
    let currency = numString && numString.toString().replace(/[$,%\s]/g, "");
    return Number(currency);
  };

  return (
    <Spin size="large" spinning={loading}>
      {loading ? <Loader /> : null}
      <Row className="content-header">
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <h2>Add Purchase Order</h2>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          className="d-flex f-justify-end"
        >
          <Link to={UPLOAD_PO_ROUTE}>
            <Button className="btn-primary">
              {" "}
              <span className="btn-icon icon-upload"></span>Upload New
            </Button>
          </Link>
        </Col>
      </Row>
      <Form
        form={form}
        onFinish={(e) => onSubmit(e)}
        validateTrigger="onBlur"
        className="form-vertical-t2"
        layout="vertical"
      >
        <Row className="">
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div className="form-vertical-t2 form-horizontal-t2 form-layout-3">
              {" "}
              <Row className="">
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <VendorDropdown
                    create
                    vendor={vendor}
                    label="Vendor"
                    index={0}
                    getValue={(val) => {
                      // form.setFieldsValue({ vendor: val.value });
                      setSelectedVendor({ ...val });
                    }}
                  />
                </Col>
              </Row>
            </div>

            <Row className="">
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                className="select-vendor-details-wrapper"
              >
                <div className="address-details">
                  <p className="label-vendor-name">{selectedVendor.value}</p>
                  <p className="label-vendor-number">+1 (575) 099 5820</p>
                  <p>957 Linda Drive New Kensington, PA United States</p>
                </div>
              </Col>
            </Row>
          </Col>

          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            className="d-flex f-justify-end"
          >
            {" "}
            <div className="w-100 form-horizontal-t2 form-layout-2">
              <Form.Item
                label="Issue Date"
                name="issue_date"
                rules={[
                  {
                    required: true,
                    message: "Please provide the issue date",
                  },
                ]}
              >
                <DatePicker format={"YYYY-MM-DD"} placeholder="YYYY-MM-DD" />
              </Form.Item>
              <Form.Item label="PO Number" name="po_number">
                <Input />
              </Form.Item>
              <Form.Item label="Po w/o contract" name="po_wo_contract">
                <Switch />
              </Form.Item>
            </div>
          </Col>
        </Row>

        <h4 className="mt-1">Billing Details</h4>
        <Row className="" gutter={24} align="top">
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Form.Item label="Contact Person" name="contact_person">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Form.Item label="Contact No." name="contact_number">
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label="Address" name="address">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row className="" gutter={24} align="top">
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Form.Item
              label="Email"
              name="po_email"
              rules={[
                {
                  type: "email",
                  message: "Email is not valid.",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Form.Item label="Delivery Date" name="delivery_date">
              <DatePicker format={"YYYY-MM-DD"} placeholder="YYYY-MM-DD" />
            </Form.Item>
          </Col>
        </Row>

        <h4 className="mt-1">SKU Details</h4>
        <Row className="mt-2" align="top">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form form={tableForm}>
              <Table
                className="table-t2 table-form-inline"
                size="middle"
                columns={columns}
                dataSource={tableData}
                pagination={false}
                summary={() => {
                  return (
                    <>
                      <Table.Summary.Row className="invoice-total-wrapper">
                        <Table.Summary.Cell
                          colSpan={5}
                          className="itw-row-2 align-right label-total-amount pr-2"
                        >
                          <Button
                            className="btn-tertiary btn-clr-type3 float-left"
                            onClick={addRow}
                          >
                            <span className="btn-icon icon-add-new"></span> Add
                            Row
                          </Button>
                          <div className="float-right">Total Amount</div>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell
                          colSpan={2}
                          className="itw-row-2 label-amount"
                        >
                          <Text type="">{`$${totalAmount
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            </Form>
          </Col>
        </Row>
        <Row className="mt-2" align="top">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Button
              onClick={() => {
                history.goBack();
              }}
              className="btn-primary btn-t2 mr-1"
            >
              <span class="btn-icon icon-arrow-square-left"></span> Back
            </Button>
            <Button className="btn-primary mr-1" htmlType="submit">
              <span className="btn-icon icon-tick"></span> Confirm
            </Button>
            <Authorized roleList={ROLES_PO_ADD_VIEW}>
              <>
                {po &&
                  po.purchase_order_items &&
                  po.purchase_order_items.some(
                    (item) => !isEmpty(item.discrepancies)
                  ) && (
                    <Button className="btn-primary" onClick={sendForApproval}>
                      <span className="btn-icon icon-tick"></span> Send for
                      Approval
                    </Button>
                  )}
              </>
            </Authorized>
          </Col>
        </Row>
      </Form>
      {notification.open && notification.message ? (
        <Notification
          msg={notification.message}
          type={notification.type}
          clearMsg={() => {
            dispatch(actionDispatch(HIDE_NOTIFICATION));
          }}
        />
      ) : null}
    </Spin>
  );
};
