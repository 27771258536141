import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Card, Badge, Row, Col } from "antd";
import { Column } from "@ant-design/charts";
import Loader from "../Loader";

const ColumnChartOld = ({
  dataSet,
  types,
  labelText = "",
  seriesField = "name",
  formatter = "",
}) => {
  const [indexNo, setIndexNo] = useState(0);

  const [selectedType, setSelectedType] = useState("");
  const [isDefaultMax, setIsDefaultMax] = useState(true);

  useEffect(() => {
    types && setSelectedType(types[0]);
  }, [types]);

  useEffect(() => {
    setIsDefaultMax(true);
    dataSet[indexNo] &&
      dataSet[indexNo].data &&
      dataSet[indexNo].data.forEach((d) => {
        if (d.number > 15 || d.amount > 1000 || d.totalProcessedTime > 20) {
          setIsDefaultMax(false);
          return;
        }
      });
  }, [selectedType, dataSet]);

  var config = {
    yAxis: {
      tickInterval: dataSet[indexNo].interval ? dataSet[indexNo].interval : 3,
      max: isDefaultMax ? 15 : null,
      grid: {
        line: {
          style: {
            stroke: "black",
            lineWidth: 2,
            lineDash: [4, 5],
            strokeOpacity: 0.03,
            cursor: "pointer",
          },
        },
      },
      label: {
        formatter: (v) => {
          return formatter + v;
        },
      },
    },
    tickLine: null,
    data: dataSet[indexNo].data,
    marginRatio: dataSet[indexNo].type === "Week" ? 0.1 : 0.1,
    spacing: 10,
    columnStyle: {
      radius: [5, 5, 5, 5],
      lineWidth: 1,
      cursor: "pointer",
    },
    cornerRadius: 10,
    color: dataSet[0].legend
      ? dataSet[0].legend.map((l) => l.color)
      : ["#13c2c2", "#FFA200"],
    colorField: "type",
    isGroup: true,
    xField: dataSet[indexNo].xField,
    yField: dataSet[indexNo].yField ? `${dataSet[indexNo].yField}` : "number",
    seriesField: seriesField,
    height: 256,
    maxColumnWidth: 20,
    legend: false,
  };

  return (
    <Card className="dashboad-chart-box dashboard-card-t1 ">
      <Row className="mb-1">
        <Col xs={12} sm={12} md={24} lg={24} xl={24} xxl={10}>
          <h3>{labelText} </h3>
        </Col>

        <Col xs={12} sm={12} md={24} lg={24} xl={24} xxl={14} s>
          {types && (
            <div className="db-tabs-buttons-wrapper-t2 float-right m-0 ">
              {types.map((type, i) => (
                <span
                  className={selectedType === type ? "activateLink" : ""}
                  onClick={() => {
                    setIndexNo(i);
                    setSelectedType(type);
                  }}
                >
                  {type}
                </span>
              ))}
            </div>
          )}

          <div className="db-legend-t1 ">
            {dataSet[indexNo] &&
              dataSet[indexNo].legend &&
              dataSet[indexNo].legend.map((t) => (
                <Badge text={t.type} color={t.color} />
              ))}
          </div>
        </Col>
      </Row>
      {dataSet && dataSet[indexNo] && !_.isEmpty(dataSet[indexNo].data) ? (
        <Column {...config} />
      ) : (
        <Loader />
      )}
    </Card>
  );
};

export default ColumnChartOld;
