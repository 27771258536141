import React, { useEffect, useState } from "react";
import { Row, Col, Button, Image } from "antd";
import NoFilesChoosen from "../../Common/NoFilesChoosen/NoFilesChoosen";
import Uploader from "../../Common/Uploader";
import history from "../../History/history";
import DataList from "./DataList";
import Dropdown from "./Dropdown";
import PricingSheetInput from "./PricingSheetInput";
import VendorDropdown from "../../Common/VendorDropdown";
import { useDispatch, useSelector } from "react-redux";
import { getFromLocal } from "../../utils/cache";
import { LOCAL_STORAGE_KEYS } from "../../utils/constants";
import { getContractUploaderAPI, uploadDataAPI } from "../../actions/Creators/data-upload";
import { triggerNotification } from "../../actions/Creators";

const Index = () => {
  const dispatch = useDispatch();
  const vendors = useSelector((state) => state.vendor);
  const [selVendor, setSelVendor] = useState("");

  const [files, setFiles] = useState([]);
  const [type, setType] = useState("New PO data");
  const [contract, setContract] = useState("Select Contract");
  const [contractList, setContractList] = useState([]);
  const [pricingData, setPricingData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const acceptedFormats = ["xlsx", "xls"];
  const setFilteredFiles = (files) => {
    setFiles(files.filter((file) => acceptedFormats.includes(file.name.split(".").reverse()[0])));
  };
  const onRemoveFile = (key) => {
    setFiles(files.filter((file, index) => index !== key));
  };

  useEffect(() => {
    const getContracts = async () => {
      try {
        const res = await getContractUploaderAPI();

        setContractList(res?.data);
      } catch (error) {
        setContractList([]);
      }
    };

    getContracts();
  }, []);

  const optionList = [
    { id: 1, title: "New PO data" },
    { id: 2, title: "New pricing Sheet" },
  ];

  return (
    <div>
      <VendorDropdown
        vendor={vendors}
        getValue={(v) => {
          setSelVendor(v.key);
        }}
        addAll={false}
        id={0}
        defaultValue={"Select Vendor"}
        create={false}
      />
      <Dropdown disabled={false} type={contract} setType={setContract} optionList={contractList} />
      <Dropdown disabled={false} type={type} setType={setType} optionList={optionList} />
      {type === "New pricing Sheet" && <PricingSheetInput setPricingData={setPricingData} pricingData={pricingData} />}
      <Row className="content-header">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <h3>Upload Relevant Data</h3>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Uploader
                files={files}
                setFiles={(files) => setFilteredFiles(files)}
                type="excel sheet file"
                accept={".xlsx, .xls"}
                customRequest={async (file) => {
                  try {
                    if (contract === "Select Contract") {
                      dispatch(triggerNotification("error", "Please Select a contract"));
                      return;
                    }

                    if (!selVendor) {
                      setFiles([]);
                      dispatch(triggerNotification("error", "Vendor can't be empty"));
                      return;
                    }
                    if (type === "New pricing Sheet" && Object.keys(pricingData)?.length < 2) {
                      setFiles([]);
                      dispatch(triggerNotification("error", "Fill dates before uploading"));
                      return;
                    }

                    setLoading(true);

                    const formData = new FormData();
                    formData.append("file", file);

                    const data = await uploadDataAPI(
                      {
                        type,
                        issue_date: pricingData?.issue_date,
                        effective_date: pricingData?.effective_date,
                        vendor: selVendor,
                        contract_id: contractList.find((x) => x.title === contract).id,
                        contractTemplate: contractList.find((x) => x.title === contract).templated_contract,
                      },
                      formData
                    );
                    setLoading(false);
                    setFiles([{ ...data, name: file?.name }]);
                  } catch (error) {
                    setFiles([]);
                    setLoading(false);
                    setError(true);
                    dispatch(triggerNotification("error", "Something went wrong! Please try again later."));
                  }
                }}
                // uploadURL={`http://localhost:8080/api/batch/upload/data?type=${type}&issue_date=${pricingData?.issue_date}&effective_date=${pricingData?.effective_date}&vendor=${selVendor}`}
                format="excel"
                pricingData={pricingData}
                uploaderType={type}
                setLoading={setLoading}
                loading={loading}
                setError={setError}
                vendor={selVendor}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {type === "New pricing Sheet" && Object.keys(pricingData)?.length < 2 ? <NoFilesChoosen /> : files.length > 0 ? <DataList files={files} removeFile={onRemoveFile} /> : <NoFilesChoosen />}
    </div>
  );
};

export default Index;
