import { getYtdPurchase, getRemainingDays, getRebateTier, getRebatableSites, getNonRebatableSites, getAllRebatesTier, getAllRebatesTierRow, getSitesGeneratingRebates } from "../../Services/Rebates";
import { HIDE_LOADER, SHOW_LOADER, SHOW_NOTIFICATION, GET_VENDOR_LIST, SET_VENDOR } from "../Types";
import { actionDispatch } from "./";
import { saveToLocal } from "../../utils/cache";
import { LOCAL_STORAGE_KEYS } from "../../utils/constants";

export const getYtdPurchaseAPI = async () => {
  try {
    const res = await getYtdPurchase();
    return res;
  } catch (error) {
    return error.response;
  }
};

export const getRemainingDaysAPI = async () => {
  try {
    const res = await getRemainingDays();
    return res;
  } catch (error) {
    return error.response;
  }
};

export const getRebateTierAPI = async () => {
  try {
    const res = await getRebateTier();
    return res;
  } catch (error) {
    return error.response;
  }
};

export const getAllRebatesTierRowAPI = async (query) => {
  try {
    const res = await getAllRebatesTierRow(query);
    return res;
  } catch (error) {
    return error.response;
  }
};

export const getRebatableSitesAPI = async () => {
  try {
    const res = await getRebatableSites();
    return res;
  } catch (error) {
    return error.response;
  }
};

export const getNonRebatableSitesAPI = async () => {
  try {
    const res = await getNonRebatableSites();
    return res;
  } catch (error) {
    return error.response;
  }
};

export const getAllRebatesTierAPI = async () => {
  try {
    const res = await getAllRebatesTier();
    return res;
  } catch (error) {
    return error.response;
  }
};

export const getSitesGeneratingRebatesAPI = async () => {
  try {
    const res = await getSitesGeneratingRebates();
    return res;
  } catch (error) {
    return error.response;
  }
};
