import * as ActionTypes from "../actions/Types";

const initialState = {
  po: {},
  count: 0,
  pos: [],
  poFileUrl: null
};

export const po = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.VIEW_ALL_PO:
      return {...state, pos: action.payload, count: action.payload.count };
    case ActionTypes.VIEW_PO:
      return { ...state, po: action.payload };
    case ActionTypes.UPDATE_PO:
      return { ...state, po: action.payload };
    case ActionTypes.DELETE_PO:
      return {...state, count: state.count-1 };
    case ActionTypes.CREATE_PO_MANUALLY:
      return { ...state };
      case ActionTypes.GET_PO_FILE:
        return {
          ...state,
          poFileUrl: action.payload,
        };
      case ActionTypes.CLEAR_PO_FILE_URL:
        return {
          ...state,
          poFileUrl: null,
        };
    default:
      return state;
  }
};


 
 