import React from "react";
import { amount } from "../../utils/helpers";
import "./graph.css";

const RebateTier = ({ rebateTiers, rebatableAmount, currentRebate }) => {
  // sorted by id
  const sortedItem = rebateTiers?.success?.sort((a, b) => a.id - b.id);
  //   getting the id of current rebate, so that we can apply css from current id till 0
  const currentTierID = rebateTiers?.success?.find((item) => item.tier_name === currentRebate);
  const rebateAmount = rebatableAmount?.success?.rebatableSitesTotalResult[0]?.grand_total_amount;

  return (
    <div>
      <div className="card-custom">
        <div className="heading-card"></div>
        {/* <!-- heading-card --> */}

        <div className="custom-progress-tracker-wrap topTooltip">
          <div className="progress-track">
            {sortedItem?.map((item, index) => (
              <div className="progress-tier" key={index}>
                <div className={currentRebate === item?.tier_name ? "toolTiper" : "d-none"} style={{ left: ((rebateAmount - item?.tier_min) / item?.tier_max) * 100 + "%" }}>
                  <div className="tooltop-amount">${amount.format(rebateAmount)}</div>
                </div>
              </div>
            ))}
          </div>
          {/* <!-- progress-track --> */}
        </div>
        {/* <!-- custom-progress-tracker-wrap bottomNumbers--> */}

        <div className="custom-progress-tracker-wrap">
          <div className="progress-track">
            {/* <!-- progress-tier --> */}
            {sortedItem?.map((item, index) => (
              <div className="progress-tier" key={index}>
                <div className="txt-wrap">
                  <h3 className={item?.id <= currentTierID.id ? "active-heading" : `non-active-heading`}>{item?.tier_name}</h3>
                  <p className={item?.id <= currentTierID.id ? "active-heading" : `non-active-heading`}>{item?.tier_rebate === 0 ? "No Rebate" : item?.tier_rebate + "%"}</p>
                </div>
                <div className="progress-bar" style={item?.id < currentTierID.id ? { maxWidth: "100%" } : item?.id === currentTierID.id ? { maxWidth: ((rebateAmount - item?.tier_min) / item?.tier_max) * 100 + "%" } : {}}></div>
              </div>
            ))}
          </div>
          {/* <!--progress-track --> */}
        </div>
        {/* <!-- custom-progress-tracker --> */}

        <div className="custom-progress-tracker-wrap bottomNumbers">
          <div className="progress-track">
            {sortedItem?.map((item, index) => (
              <div className="progress-tier" key={index}>
                <p>{}</p>
                <div className="trierNumber">${amount.format(item?.tier_min)}</div>
              </div>
            ))}
          </div>
          {/* <!-- progress-track --> */}
        </div>
        {/* <!-- custom-progress-tracker-wrap bottomNumbers--> */}
      </div>
      {/* <!--card-custom--> */}
    </div>
  );
};

export default RebateTier;
