import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Input, Button, Collapse, Avatar } from "antd";
import { getUser, updateUser } from "../../actions/Creators/User";
import { HIDE_NOTIFICATION } from "../../actions/Types";
import { actionDispatch } from "../../actions/Creators";
import Notification from "../../Common/Notification";
import Loader from "../../Common/Loader";

const UserProfile = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { Panel } = Collapse;

  useEffect(() => {
    dispatch(getUser());
  }, []);

  const onFinish = (values) => {
    dispatch(updateUser(values));
  };
  const notification = useSelector((state) => state.commonReducer.notification);
  const loading = useSelector((state) => state.commonReducer.loader);
  const user = useSelector((state) => state.auth.user);
  return (
    <>
      {notification.open && notification.message ? (
        <Notification
          msg={notification.message}
          type={notification.type}
          clearMsg={() => {
            dispatch(actionDispatch(HIDE_NOTIFICATION));
          }}
        />
      ) : null}
      {loading ? (
        <Loader />
      ) : (
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            name="display_picture"
            style={{ textAlign: "center", marginBottom: "30px" }}
          >
            <Avatar
              src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
              size={100}
            />
          </Form.Item>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label="Email" name="email" initialValue={user.email}>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label="Organization"
                name="organization"
                initialValue={user.organization && user.organization.name}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label="First Name"
                name="first_name"
                initialValue={user.first_name}
                rules={[
                  { required: true, message: "Please input first name!" },
                  { max: 50, message: "Max character limit reached!" },
                ]}
                required={false}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label="Last Name"
                name="last_name"
                initialValue={user.last_name}
                rules={[
                  { required: true, message: "Please input last name!" },
                  { max: 50, message: "Max character limit reached!" },
                ]}
                required={false}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Collapse style={{ marginRight: "24px" }}>
            <Panel header="Create new password">
              <Row gutter={24}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="Old Password"
                    name="oldPassword"
                    required={false}
                  >
                    <Input.Password />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="New Password"
                    name="newPassword"
                    dependencies={["oldPassword"]}
                  >
                    <Input.Password />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
          </Collapse>

          <Form.Item>
            <Button
              className="btn-primary mt-2-im"
              type="primary"
              htmlType="submit"
            >
              <span className="btn-icon icon-tick"></span> Save
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default UserProfile;
