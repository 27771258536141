import * as ActionTypes from "../actions/Types";
import { getFromLocal } from "../utils/cache";
import { LOCAL_STORAGE_KEYS } from "../utils/constants";

const initialState = {
  selectedOrganization:
    getFromLocal(LOCAL_STORAGE_KEYS.SELECTED_ORGANIZATION, true, true) || {},
};

export const header = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_THEME:
      return {
        ...state,
        theme: action.payload.theme,
      };
    case ActionTypes.SET_VENDOR:
      return {
        ...state,
        selectedVendor: action.payload.selectedVendor,
      };
    case ActionTypes.SET_ORGANIZATION:
      return {
        ...state,
        selectedOrganization: action.payload.selectedOrganization,
      };
    case ActionTypes.LOGOUT:
      return { selectedOrganization: {} };
    default:
      return state;
  }
};
