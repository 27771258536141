export const operationalRebates = [
  {
    customer_location_2022: "ALPINE VALLEY BREAD COMPANY",
    address_2022: "300 W SOUTHERN AVENUE",
    city_2022: "Mesa",
    state_2022: "AZ",
    Spend2022: 0,
    customer_location_2023: "ALPINE VALLEY BREAD COMPANY",
    address_2023: "300 W SOUTHERN AVENUE",
    city_2023: "Mesa",
    state_2023: "AZ",
    Spend2023: 9578.6,
    "Growth %": 1,
    "Growth/De-growth": "Growth",
    "Incentive %": 0.025,
    "Incentive Amount": 239.46500000000003,
    "Spend for 10%": 0,
    "Spend Required": -9578.6,
  },
  {
    customer_location_2022: "AVB INC DAVES KILLER BREAD",
    address_2022: "5209 SE INTERNATIONAL WAY",
    city_2022: "Milwaukie",
    state_2022: "OR",
    Spend2022: 20080.27,
    customer_location_2023: "AVB INC DAVES KILLER BREAD",
    address_2023: "5209 SE INTERNATIONAL WAY",
    city_2023: "Milwaukie",
    state_2023: "OR",
    Spend2023: 7717.27,
    "Growth %": -0.6156789724440956,
    "Growth/De-growth": "De-growth",
    "Incentive Amount": 0,
    "Spend for 10%": 22088.297000000002,
    "Spend Required": 14371.027000000002,
  },
  {
    customer_location_2022: "BAILEY STREET BAKERY LLC",
    address_2022: "165 BAILEY ST. SW",
    city_2022: "Atlanta",
    state_2022: "GA",
    Spend2022: 2220,
    customer_location_2023: "BAILEY STREET BAKERY LLC",
    address_2023: "165 BAILEY ST. SW",
    city_2023: "Atlanta",
    state_2023: "GA",
    Spend2023: 13125.19,
    "Growth %": 4.912247747747748,
    "Growth/De-growth": "Growth",
    "Incentive %": 0.025,
    "Incentive Amount": 272.62975,
    "Spend for 10%": 2442,
    "Spend Required": -10683.19,
  },
  {
    customer_location_2022: "DERST BAKING COMPANY",
    address_2022: "1311 W 52ND ST",
    city_2022: "Savannah",
    state_2022: "GA",
    Spend2022: 16596.08,
    customer_location_2023: "DERST BAKING COMPANY",
    address_2023: "1311 W 52ND ST",
    city_2023: "Savannah",
    state_2023: "GA",
    Spend2023: 10295.78,
    "Growth %": -0.3796257911506814,
    "Growth/De-growth": "De-growth",
    "Incentive Amount": 0,
    "Spend for 10%": 18255.688000000002,
    "Spend Required": 7959.908000000001,
  },
  {
    customer_location_2022: "FBC OF HENDERSON LLC",
    address_2022: "501 CONESTOGA WAY",
    city_2022: "Henderson",
    state_2022: "NV",
    Spend2022: 56769.56,
    customer_location_2023: "FBC OF HENDERSON LLC",
    address_2023: "501 CONESTOGA WAY",
    city_2023: "Henderson",
    state_2023: "NV",
    Spend2023: 47031.07,
    "Growth %": -0.17154422193865865,
    "Growth/De-growth": "De-growth",
    "Incentive Amount": 0,
    "Spend for 10%": 62446.516,
    "Spend Required": 15415.446000000004,
  },
  {
    customer_location_2022: "FBC OF KNOXVILLE LLC",
    address_2022: "3100 NW PARK DR",
    city_2022: "Knoxville",
    state_2022: "TN",
    Spend2022: 9862.66,
    customer_location_2023: "FBC OF KNOXVILLE LLC",
    address_2023: "3100 NW PARK DR",
    city_2023: "Knoxville",
    state_2023: "TN",
    Spend2023: 17989.51,
    "Growth %": 0.8240018412882527,
    "Growth/De-growth": "Growth",
    "Incentive %": 0.025,
    "Incentive Amount": 203.17125,
    "Spend for 10%": 10848.926000000001,
    "Spend Required": -7140.583999999997,
  },
  {
    customer_location_2022: "FBC OF LENEXA LLC",
    address_2022: "8960 MARSHALL DR",
    city_2022: "Lenexa",
    state_2022: "KS",
    Spend2022: 14712.79,
    customer_location_2023: "FBC OF LENEXA LLC",
    address_2023: "8960 MARSHALL DR",
    city_2023: "Lenexa",
    state_2023: "KS",
    Spend2023: 32468.03,
    "Growth %": 1.2067894668516301,
    "Growth/De-growth": "Growth",
    "Incentive %": 0.025,
    "Incentive Amount": 443.881,
    "Spend for 10%": 16184.069000000003,
    "Spend Required": -16283.960999999996,
  },
  {
    customer_location_2022: "FBC OF MODESTO LLC",
    address_2022: "736 MARIPOSA RD",
    city_2022: "Modesto",
    state_2022: "CA",
    Spend2022: 80153.2,
    customer_location_2023: "FBC OF MODESTO LLC",
    address_2023: "736 MARIPOSA RD",
    city_2023: "Modesto",
    state_2023: "CA",
    Spend2023: 53680.12,
    "Growth %": -0.33028101186228365,
    "Growth/De-growth": "De-growth",
    "Incentive Amount": 0,
    "Spend for 10%": 88168.52,
    "Spend Required": 34488.4,
  },
  {
    customer_location_2022: "FL SPEC FDS OF MONTGOMERY",
    address_2022: "140 FOLMER PARKWAY",
    city_2022: "Montgomery",
    state_2022: "AL",
    Spend2022: 53530.17,
    customer_location_2023: "FL SPEC FDS OF MONTGOMERY",
    address_2023: "140 FOLMER PARKWAY",
    city_2023: "Montgomery",
    state_2023: "AL",
    Spend2023: 8921.51,
    "Growth %": -0.8333367893283358,
    "Growth/De-growth": "De-growth",
    "Incentive Amount": 0,
    "Spend for 10%": 58883.187000000005,
    "Spend Required": 49961.677,
  },
  {
    customer_location_2022: "FLOWERS BAKERY OF BIRMINGHAM",
    address_2022: "900 NORTH 16TH ST",
    city_2022: "Birmingham",
    state_2022: "AL",
    Spend2022: 50097.2,
    customer_location_2023: "FLOWERS BAKERY OF BIRMINGHAM",
    address_2023: "900 NORTH 16TH ST",
    city_2023: "Birmingham",
    state_2023: "AL",
    Spend2023: 26706.86,
    "Growth %": -0.46689914805617877,
    "Growth/De-growth": "De-growth",
    "Incentive Amount": 0,
    "Spend for 10%": 55106.92,
    "Spend Required": 28400.059999999998,
  },
  {
    customer_location_2022: "FLOWERS BAKERY OF CLEVELAND",
    address_2022: "1335 S OCOEE ST",
    city_2022: "Cleveland",
    state_2022: "TN",
    Spend2022: 199.59,
    customer_location_2023: "FLOWERS BAKERY OF CLEVELAND",
    address_2023: "1335 S OCOEE ST",
    city_2023: "Cleveland",
    state_2023: "TN",
    Spend2023: 1547.46,
    "Growth %": 6.753194047797987,
    "Growth/De-growth": "Growth",
    "Incentive %": 0.025,
    "Incentive Amount": 33.69675,
    "Spend for 10%": 219.54900000000004,
    "Spend Required": -1327.911,
  },
  {
    customer_location_2022: "FLOWERS BAKERY OF CROSSVILLE",
    address_2022: "1067 N MAIN ST",
    city_2022: "Crossville",
    state_2022: "TN",
    Spend2022: 75.15,
    customer_location_2023: "FLOWERS BAKERY OF CROSSVILLE",
    address_2023: "1067 N MAIN ST",
    city_2023: "Crossville",
    state_2023: "TN",
    Spend2023: 231,
    "Growth %": 2.0738522954091816,
    "Growth/De-growth": "Growth",
    "Incentive %": 0.025,
    "Incentive Amount": 3.89625,
    "Spend for 10%": 82.665,
    "Spend Required": -148.33499999999998,
  },
  {
    customer_location_2022: "FLOWERS BAKERY OF LONDON",
    address_2022: "501 E FOURTH ST",
    city_2022: "London",
    state_2022: "KY",
    Spend2022: 78935.63,
    customer_location_2023: "FLOWERS BAKERY OF LONDON",
    address_2023: "501 E FOURTH ST",
    city_2023: "London",
    state_2023: "KY",
    Spend2023: 52362.53,
    "Growth %": -0.3366426542741219,
    "Growth/De-growth": "De-growth",
    "Incentive Amount": 0,
    "Spend for 10%": 86829.19300000001,
    "Spend Required": 34466.663000000015,
  },
  {
    customer_location_2022: "FLOWERS BAKING CO OF BARDSTOWN",
    address_2022: "1755 PKWY DR",
    city_2022: "Bardstown",
    state_2022: "KY",
    Spend2022: 8093.76,
    customer_location_2023: "FLOWERS BAKING CO OF BARDSTOWN",
    address_2023: "1755 PKWY DR",
    city_2023: "Bardstown",
    state_2023: "KY",
    Spend2023: 19568.61,
    "Growth %": 1.4177403332937968,
    "Growth/De-growth": "Growth",
    "Incentive %": 0.025,
    "Incentive Amount": 286.87125000000003,
    "Spend for 10%": 8903.136,
    "Spend Required": -10665.474,
  },
  {
    customer_location_2022: "FLOWERS BAKING CO OF BATESVILLE",
    address_2022: "1223 S SAINT LOUIS ST",
    city_2022: "Batesville",
    state_2022: "AR",
    Spend2022: 2538.13,
    "Growth %": -1,
    "Growth/De-growth": "De-growth",
    "Incentive Amount": 0,
    "Spend for 10%": 2791.943,
    "Spend Required": 2791.943,
  },
  {
    customer_location_2022: "FLOWERS BAKING CO OF DENTON",
    address_2022: "4210 EDWARDS RD",
    city_2022: "Denton",
    state_2022: "TX",
    Spend2022: 12346.89,
    customer_location_2023: "FLOWERS BAKING CO OF DENTON",
    address_2023: "4210 EDWARDS RD",
    city_2023: "Denton",
    state_2023: "TX",
    Spend2023: 21381.01,
    "Growth %": 0.7316919483367876,
    "Growth/De-growth": "Growth",
    "Incentive %": 0.025,
    "Incentive Amount": 225.85299999999998,
    "Spend for 10%": 13581.579,
    "Spend Required": -7799.430999999999,
  },
  {
    customer_location_2022: "FLOWERS BAKING CO OF EL PASO",
    address_2022: "301 NORTH DALLAS",
    city_2022: "El Paso",
    state_2022: "TX",
    Spend2022: 13255.63,
    customer_location_2023: "FLOWERS BAKING CO OF EL PASO",
    address_2023: "301 NORTH DALLAS",
    city_2023: "El Paso",
    state_2023: "TX",
    Spend2023: 22292.75,
    "Growth %": 0.6817571099977897,
    "Growth/De-growth": "Growth",
    "Incentive %": 0.025,
    "Incentive Amount": 225.92800000000003,
    "Spend for 10%": 14581.193000000001,
    "Spend Required": -7711.556999999999,
  },
  {
    customer_location_2022: "FLOWERS BAKING CO OF HOUSTON",
    address_2022: "3000 WASHINGTON AVE",
    city_2022: "Houston",
    state_2022: "TX",
    Spend2022: 22044.71,
    customer_location_2023: "FLOWERS BAKING CO OF HOUSTON",
    address_2023: "3000 WASHINGTON AVE",
    city_2023: "Houston",
    state_2023: "TX",
    Spend2023: 20201.98,
    "Growth %": -0.08359057569820603,
    "Growth/De-growth": "De-growth",
    "Incentive Amount": 0,
    "Spend for 10%": 24249.181,
    "Spend Required": 4047.201000000001,
  },
  {
    customer_location_2022: "FLOWERS BAKING CO OF LYNCHBURG",
    address_2022: "1905 HOLLINS MILL ROAD",
    city_2022: "Lynchburg",
    state_2022: "VA",
    Spend2022: 23786.31,
    customer_location_2023: "FLOWERS BAKING CO OF LYNCHBURG",
    address_2023: "1905 HOLLINS MILL ROAD",
    city_2023: "Lynchburg",
    state_2023: "VA",
    Spend2023: 24459.27,
    "Growth %": 0.028291904040601466,
    "Growth/De-growth": "Growth",
    "Incentive %": 0.02,
    "Incentive Amount": 13.459199999999983,
    "Spend for 10%": 26164.941000000003,
    "Spend Required": 1705.671000000002,
  },
  {
    customer_location_2022: "FLOWERS BAKING CO OF NEWTON LLC",
    address_2022: "1633 FISCHER COURT",
    city_2022: "Newton",
    state_2022: "NC",
    Spend2022: 24905.41,
    customer_location_2023: "FLOWERS BAKING CO OF NEWTON LLC",
    address_2023: "1633 FISCHER COURT",
    city_2023: "Newton",
    state_2023: "NC",
    Spend2023: 46674.36,
    "Growth %": 0.8740651127606412,
    "Growth/De-growth": "Growth",
    "Incentive %": 0.025,
    "Incentive Amount": 544.22375,
    "Spend for 10%": 27395.951,
    "Spend Required": -19278.409,
  },
  {
    customer_location_2022: "FLOWERS BAKING CO OF OXFORD INC",
    address_2022: "700 LINCOLN ST",
    city_2022: "Oxford",
    state_2022: "PA",
    Spend2022: 48014.31,
    customer_location_2023: "FLOWERS BAKING CO OF OXFORD INC",
    address_2023: "700 LINCOLN ST",
    city_2023: "Oxford",
    state_2023: "PA",
    Spend2023: 68852.06,
    "Growth %": 0.43399040827619934,
    "Growth/De-growth": "Growth",
    "Incentive %": 0.025,
    "Incentive Amount": 520.94375,
    "Spend for 10%": 52815.741,
    "Spend Required": -16036.318999999996,
  },
  {
    customer_location_2022: "FLOWERS BAKING CO OF SAN ANTONIO",
    address_2022: "6000 NE LOOP 410",
    city_2022: "San Antonio",
    state_2022: "TX",
    Spend2022: 39809.02,
    customer_location_2023: "FLOWERS BAKING CO OF SAN ANTONIO",
    address_2023: "6000 NE LOOP 410",
    city_2023: "San Antonio",
    state_2023: "TX",
    Spend2023: 25411.26,
    "Growth %": -0.3616707972213332,
    "Growth/De-growth": "De-growth",
    "Incentive Amount": 0,
    "Spend for 10%": 43789.922,
    "Spend Required": 18378.662,
  },
  {
    customer_location_2022: "FLOWERS BAKING CO OF SUWANEE",
    address_2022: "2900 ROLLING PIN LANE STE A",
    city_2022: "Suwanee",
    state_2022: "GA",
    Spend2022: 52262.24,
    customer_location_2023: "FLOWERS BAKING CO OF SUWANEE",
    address_2023: "2900 ROLLING PIN LANE STE A",
    city_2023: "Suwanee",
    state_2023: "GA",
    Spend2023: 21507.17,
    "Growth %": -0.588475924491564,
    "Growth/De-growth": "De-growth",
    "Incentive Amount": 0,
    "Spend for 10%": 57488.464,
    "Spend Required": 35981.294,
  },
  {
    customer_location_2022: "FLOWERS BAKING CO OF THOMASVILLE",
    address_2022: "300 MADISON ST",
    city_2022: "Thomasville",
    state_2022: "GA",
    Spend2022: 67626.73,
    customer_location_2023: "FLOWERS BAKING CO OF THOMASVILLE",
    address_2023: "300 MADISON ST",
    city_2023: "Thomasville",
    state_2023: "GA",
    Spend2023: 18473.94,
    "Growth %": -0.7268248812266983,
    "Growth/De-growth": "De-growth",
    "Incentive Amount": 0,
    "Spend for 10%": 74389.403,
    "Spend Required": 55915.463,
  },
  {
    customer_location_2022: "FLOWERS BAKING CO OF TYLER",
    address_2022: "1200 W ERWIN ST",
    city_2022: "Tyler",
    state_2022: "TX",
    Spend2022: 17053.43,
    customer_location_2023: "FLOWERS BAKING CO OF TYLER",
    address_2023: "1200 W ERWIN ST",
    city_2023: "Tyler",
    state_2023: "TX",
    Spend2023: 18672.04,
    "Growth %": 0.09491404368505342,
    "Growth/De-growth": "Growth",
    "Incentive %": 0.02,
    "Incentive Amount": 32.372200000000014,
    "Spend for 10%": 18758.773,
    "Spend Required": 86.73300000000017,
  },
  {
    customer_location_2022: "FLOWERS BAKING CO. OF BATON ROUGE",
    address_2022: "1504 FLORIDA BOULEVARD",
    city_2022: "Baton Rouge",
    state_2022: "LA",
    Spend2022: 42361.71,
    customer_location_2023: "FLOWERS BAKING CO. OF BATON ROUGE",
    address_2023: "1504 FLORIDA BOULEVARD",
    city_2023: "Baton Rouge",
    state_2023: "LA",
    Spend2023: 12721.5,
    "Growth %": -0.6996934259735974,
    "Growth/De-growth": "De-growth",
    "Incentive Amount": 0,
    "Spend for 10%": 46597.881,
    "Spend Required": 33876.381,
  },
  {
    customer_location_2022: "FLOWERS BAKING CO. OF BRADENTON",
    address_2022: "6490 PARKLAND DR",
    city_2022: "Sarasota",
    state_2022: "FL",
    Spend2022: 39675.02,
    customer_location_2023: "FLOWERS BAKING CO. OF BRADENTON",
    address_2023: "6490 PARKLAND DR",
    city_2023: "Sarasota",
    state_2023: "FL",
    Spend2023: 96947.35,
    "Growth %": 1.443536260347191,
    "Growth/De-growth": "Growth",
    "Incentive %": 0.025,
    "Incentive Amount": 1431.8082500000003,
    "Spend for 10%": 43642.522,
    "Spend Required": -53304.82800000001,
  },
  {
    customer_location_2022: "FLOWERS BAKING CO. OF JAMESTOWN",
    address_2022: "801 W MAIN STREET",
    city_2022: "Jamestown",
    state_2022: "NC",
    Spend2022: 167279.29,
    customer_location_2023: "FLOWERS BAKING CO. OF JAMESTOWN",
    address_2023: "801 W MAIN STREET",
    city_2023: "Jamestown",
    state_2023: "NC",
    Spend2023: 72598.86,
    "Growth %": -0.5660021034283443,
    "Growth/De-growth": "De-growth",
    "Incentive Amount": 0,
    "Spend for 10%": 184007.219,
    "Spend Required": 111408.35900000001,
  },
  {
    customer_location_2022: "FLOWERS BAKING CO. OF MIAMI",
    address_2022: "17800 NW MIAMI CT",
    city_2022: "Miami",
    state_2022: "FL",
    Spend2022: 12556.95,
    customer_location_2023: "FLOWERS BAKING CO. OF MIAMI",
    address_2023: "17800 NW MIAMI CT",
    city_2023: "Miami",
    state_2023: "FL",
    Spend2023: 12677.49,
    "Growth %": 0.009599464838197097,
    "Growth/De-growth": "Growth",
    "Incentive %": 0.02,
    "Incentive Amount": 2.410799999999981,
    "Spend for 10%": 13812.645000000002,
    "Spend Required": 1135.1550000000025,
  },
  {
    customer_location_2022: "FLOWERS BAKING CO. OF NORFOLK",
    address_2022: "1209 CORPEW AVE",
    city_2022: "Norfolk",
    state_2022: "VA",
    Spend2022: 6756.8,
    customer_location_2023: "FLOWERS BAKING CO. OF NORFOLK",
    address_2023: "1209 CORPEW AVE",
    city_2023: "Norfolk",
    state_2023: "VA",
    Spend2023: 18882.48,
    "Growth %": 1.7945891546294104,
    "Growth/De-growth": "Growth",
    "Incentive %": 0.025,
    "Incentive Amount": 303.142,
    "Spend for 10%": 7432.4800000000005,
    "Spend Required": -11450,
  },
  {
    customer_location_2022: "FLOWERS BAKING CO. OF TEXARKANA",
    address_2022: "7 JIM WALTERS DR",
    city_2022: "Texarkana",
    state_2022: "AR",
    Spend2022: 233.39,
    customer_location_2023: "FLOWERS BAKING CO. OF TEXARKANA",
    address_2023: "7 JIM WALTERS DR",
    city_2023: "Texarkana",
    state_2023: "AR",
    Spend2023: 653.2,
    "Growth %": 1.7987488752731484,
    "Growth/De-growth": "Growth",
    "Incentive %": 0.025,
    "Incentive Amount": 10.495250000000002,
    "Spend for 10%": 256.729,
    "Spend Required": -396.47100000000006,
  },
  {
    customer_location_2022: "FLOWERS BAKING CO. OF TUCKER",
    address_2022: "5055 S ROYAL ATLANTA DR",
    city_2022: "Tucker",
    state_2022: "GA",
    Spend2022: 15612.17,
    customer_location_2023: "FLOWERS BAKING CO. OF TUCKER",
    address_2023: "5055 S ROYAL ATLANTA DR",
    city_2023: "Tucker",
    state_2023: "GA",
    Spend2023: 4746.15,
    "Growth %": -0.6959967768734263,
    "Growth/De-growth": "De-growth",
    "Incentive Amount": 0,
    "Spend for 10%": 17173.387000000002,
    "Spend Required": 12427.237000000003,
  },
  {
    customer_location_2022: "FLOWERS BAKING CO. OF VILLA RICA",
    address_2022: "134 DOYLE MCCAIN DRIVE",
    city_2022: "Villa Rica",
    state_2022: "GA",
    Spend2022: 15846.3,
    customer_location_2023: "FLOWERS BAKING CO. OF VILLA RICA",
    address_2023: "134 DOYLE MCCAIN DRIVE",
    city_2023: "Villa Rica",
    state_2023: "GA",
    Spend2023: 18860.11,
    "Growth %": 0.19019013902298967,
    "Growth/De-growth": "Growth",
    "Incentive %": 0.025,
    "Incentive Amount": 75.34525000000004,
    "Spend for 10%": 17430.93,
    "Spend Required": -1429.1800000000003,
  },
  {
    customer_location_2022: "FRANKLIN BAKING COMPANY",
    address_2022: "607 NORTH CAROLINA STREET",
    city_2022: "Goldsboro",
    state_2022: "NC",
    Spend2022: 56974.1,
    customer_location_2023: "FRANKLIN BAKING COMPANY",
    address_2023: "607 NORTH CAROLINA STREET",
    city_2023: "Goldsboro",
    state_2023: "NC",
    Spend2023: 53561.27,
    "Growth %": -0.05990142889488385,
    "Growth/De-growth": "De-growth",
    "Incentive Amount": 0,
    "Spend for 10%": 62671.51,
    "Spend Required": 9110.240000000005,
  },
  {
    customer_location_2022: "Flowers Foods Shared Service Center",
    address_2022: "132 N. Broad St.",
    city_2022: "Thomasville",
    state_2022: "GA",
    Spend2022: 13739.92,
    customer_location_2023: "Flowers Foods Shared Service Center",
    address_2023: "132 N. Broad St.",
    city_2023: "Thomasville",
    state_2023: "GA",
    Spend2023: 0,
    "Growth %": -1,
    "Growth/De-growth": "De-growth",
    "Incentive Amount": 0,
    "Spend for 10%": 15113.912000000002,
    "Spend Required": 15113.912000000002,
  },
  {
    customer_location_2022: "HOLSUM BAKERY OF PHOENIX INC",
    address_2022: "2322 W LINCOLN ST",
    city_2022: "Phoenix",
    state_2022: "AZ",
    Spend2022: 1334.36,
    "Growth %": -1,
    "Growth/De-growth": "De-growth",
    "Incentive Amount": 0,
    "Spend for 10%": 1467.796,
    "Spend Required": 1467.796,
  },
  {
    customer_location_2022: "HOLSUM BAKERY OF TOLLESON INC",
    address_2022: "9600 W BUCKEYE RD",
    city_2022: "Tolleson",
    state_2022: "AZ",
    Spend2022: 52244.47,
    customer_location_2023: "HOLSUM BAKERY OF TOLLESON INC",
    address_2023: "9600 W BUCKEYE RD",
    city_2023: "Tolleson",
    state_2023: "AZ",
    Spend2023: 11310,
    "Growth %": -0.7835177579560095,
    "Growth/De-growth": "De-growth",
    "Incentive Amount": 0,
    "Spend for 10%": 57468.91700000001,
    "Spend Required": 46158.91700000001,
  },
  {
    customer_location_2022: "LEELAND BAKING COMPANY LLC",
    address_2022: "4104 LEELAND ST",
    city_2022: "Houston",
    state_2022: "TX",
    Spend2022: 36450.2,
    customer_location_2023: "LEELAND BAKING COMPANY LLC",
    address_2023: "4104 LEELAND ST",
    city_2023: "Houston",
    state_2023: "TX",
    Spend2023: 36688.05,
    "Growth %": 0.00652534142473857,
    "Growth/De-growth": "Growth",
    "Incentive %": 0.02,
    "Incentive Amount": 4.757000000000117,
    "Spend for 10%": 40095.22,
    "Spend Required": 3407.1699999999983,
  },
  {
    customer_location_2022: "LEPAGE BAKERIES CEDAR ST LLC",
    address_2022: "83 CEDAR STREET",
    city_2022: "Lewiston",
    state_2022: "ME",
    Spend2022: 20232.45,
    customer_location_2023: "LEPAGE BAKERIES CEDAR ST LLC",
    address_2023: "83 CEDAR STREET",
    city_2023: "Lewiston",
    state_2023: "ME",
    Spend2023: 8799.99,
    "Growth %": -0.565055640814632,
    "Growth/De-growth": "De-growth",
    "Incentive Amount": 0,
    "Spend for 10%": 22255.695000000003,
    "Spend Required": 13455.705000000004,
  },
  {
    customer_location_2022: "LEPAGE BAKERIES PARK ST LLC",
    address_2022: "354 LISBON STREET",
    city_2022: "Lewiston",
    state_2022: "ME",
    Spend2022: 84.36,
    customer_location_2023: "LEPAGE BAKERIES PARK ST LLC",
    address_2023: "354 LISBON STREET",
    city_2023: "Lewiston",
    state_2023: "ME",
    Spend2023: 1043.21,
    "Growth %": 11.366168800379327,
    "Growth/De-growth": "Growth",
    "Incentive %": 0.025,
    "Incentive Amount": 23.97125,
    "Spend for 10%": 92.796,
    "Spend Required": -950.414,
  },
  {
    customer_location_2022: "SOUTHERN BAKERIES INC",
    address_2022: "3355 W MEMORIAL BLVD",
    city_2022: "Lakeland",
    state_2022: "FL",
    Spend2022: 33847.82,
    customer_location_2023: "SOUTHERN BAKERIES INC",
    address_2023: "3355 W MEMORIAL BLVD",
    city_2023: "Lakeland",
    state_2023: "FL",
    Spend2023: 61685.09,
    "Growth %": 0.8224243097487518,
    "Growth/De-growth": "Growth",
    "Incentive %": 0.025,
    "Incentive Amount": 695.93175,
    "Spend for 10%": 37232.602000000006,
    "Spend Required": -24452.48799999999,
  },
  // {
  //   customer_location_2022: "TASTY BAKING COMPANY",
  //   address_2022: "4300 S 26TH ST",
  //   city_2022: "Philadelphia",
  //   state_2022: "PA",
  //   Spend2022: 34340.87,
  //   customer_location_2023: "TASTY BAKING COMPANY",
  //   address_2023: "4300 S 26TH ST",
  //   city_2023: "Philadelphia",
  //   state_2023: "PA",
  //   Spend2023: 13849.29,
  //   "Growth %": -0.5967111491351268,
  //   "Growth/De-growth": "De-growth",
  //   "Incentive Amount": 0,
  //   "Spend for 10%": 37774.95700000001,
  //   "Spend Required": 23925.66700000001,
  // },
  // {
  //   customer_location_2022: "TUSCALOOSA ORGANIC BREAD CO, LLC",
  //   address_2022: "546 15TH STREET",
  //   city_2022: "Tuscaloosa",
  //   state_2022: "AL",
  //   Spend2022: 39755.24,
  //   customer_location_2023: "TUSCALOOSA ORGANIC BREAD CO, LLC",
  //   address_2023: "546 15TH STREET",
  //   city_2023: "Tuscaloosa",
  //   state_2023: "AL",
  //   Spend2023: 19182.57,
  //   "Growth %": -0.5174832298836581,
  //   "Growth/De-growth": "De-growth",
  //   "Incentive Amount": 0,
  //   "Spend for 10%": 43730.764,
  //   "Spend Required": 24548.194000000003,
  // },
];
