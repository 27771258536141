import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    backgroundColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "left",
    marginLeft: 4,
    fontStyle: "bold",
  },
  column: {
    width: "60%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    fontSize: 13,
    paddingLeft: 1,
  },
});

const TableHeader = ({ columns }) => (
  <View style={styles.container}>
    {columns.map((column) => (
      <Text style={styles.column}>{column.title}</Text>
    ))}
  </View>
);

export default TableHeader;
