import { combineReducers } from "redux";

import { auth } from "./auth";
import { dashboard } from "./dashboard"
import { contract } from "./contract";
import { invoice } from "./invoice";
import { vendor } from "./vendor";
import { po } from "./po";
import { commonReducer } from "./common";
import { templatesReducer } from "./templates";
import { header } from "./header";
import { organizations } from "./organizations";
import { pagination } from "./pagination";
import { commentsHistory } from "./comments";
import { reports } from "./reports";

export default combineReducers({
  auth,
  dashboard,
  contract,
  invoice,
  vendor,
  po,
  commonReducer,
  templatesReducer,
  header,
  organizations,
  pagination,
  commentsHistory,
  reports
});
