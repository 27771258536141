import * as ActionTypes from "../actions/Types";

const initialState = {
  loader: false,
  notification: {
    open: false,
    message: "",
    type: "success",
    hideTime: 2000,
  },
  fileLoader: false,
};

export const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SHOW_LOADER:
      return { ...state, loader: true };
    case ActionTypes.HIDE_LOADER:
      return {
        ...state,
        loader: false,
      };
    case ActionTypes.SHOW_NOTIFICATION:
      return {
        ...state,
        notification: {
          ...action.payload,
        },
      };
    case ActionTypes.HIDE_NOTIFICATION:
      return {
        ...state,
        notification: {
          message: "",
          open: false,
          type: "",
        },
      };
    case ActionTypes.SHOW_FILE_LOADER:
      return {
        ...state,
        fileLoader: true,
      };
    case ActionTypes.HIDE_FILE_LOADER:
      return {
        ...state,
        fileLoader: false,
      };
    default:
      return state;
  }
};
