export const RESET_PASSWORD_SUCCESS =
  "Reset Password link is sent to your email successfully.";
export const UNEXPECTED_ERROR = "Something went wrong.";
export const UNPERFORMED_SAVE_ACTION = "Unable to save files. Please try again later";
export const SIGNIN_FAILURE_MSG = "Unable to log user. Please try again later.";
export const UNPERFORMED_GET_ACTION = "Unable to get record/s, please try again.";
export const UNPERFORMED_UPDATE_ACTION = "Unable to update record/s, please try again.";
export const RESET_PASSWORD_FAILED = "Unable to reset password. Please try again later."

export const CONTRACTS_SAVED_SUCCESSFULLY = "Contract(s) successfully added."
export const INVOICES_SAVED_SUCCESSFULLY = "Invoice(s) successfully added."
export const PO_SAVED_SUCCESSFULLY = "PO(s) successfully added."

export const DISCREPANCY_ADDED_SUCCESS = "Discrepancy has been added successfully."
