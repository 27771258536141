import {
  HIDE_LOADER,
  SHOW_LOADER,
  SHOW_NOTIFICATION,
  GET_ORGANIZATIONS_LIST,
  SET_ORGANIZATION,
} from "../Types";
import { actionDispatch } from ".";
import { saveToLocal } from "../../utils/cache";
import { LOCAL_STORAGE_KEYS } from "../../utils/constants";
import { getOrganizationList } from "../../Services/Organizations/organization";

export const getOrganizations = () => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getOrganizationList();
    dispatch(actionDispatch(GET_ORGANIZATIONS_LIST, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message: ex.msg,
        open: true,
        type: "error",
      })
    );
  }
};

export const setOrganization = (data) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    saveToLocal(data, LOCAL_STORAGE_KEYS.SELECTED_ORGANIZATION, true, true);
    dispatch(actionDispatch(SET_ORGANIZATION, { selectedOrganization: data }));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message: ex.msg,
        open: true,
        type: "error",
      })
    );
  }
};
