import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

const Authorized = ({ children, roleList, shouldNotIncludePersona = [""] }) => {
  const rolesArr = useSelector((state) => state.auth.roles);
  const myRoles = rolesArr.length ? rolesArr.map((roles) => roles.role.name) : [];

  //Condition for single role user
  if (myRoles.some((r) => roleList.includes(r)) && myRoles.length == 1) return children;
  //Condition for multiple role user

  /*ShouldNotIncudePersona is only checked for multiple users. 
  The conditions is checked for scenarios where we are either showing one component if a particular persona is logged in  
  and another component if another persona is logged in. 
  To allow only one component to render, the shouldNotIncludePersona check is placed with the lesser priority persona. */

  if (myRoles.some((r) => roleList.includes(r)) && !myRoles.includes(shouldNotIncludePersona[0]) && myRoles.length > 1) return children;
  else return <></>;
};

export const AuthorizedRoute = ({ children, roleList }) => {
  const rolesArr = useSelector((state) => state.auth.roles);
  const myRoles = rolesArr.length ? rolesArr.map((roles) => roles.role.name) : [];

  if (myRoles.some((r) => roleList.includes(r))) return children;
  else return <Redirect to="/" />;
};

export default Authorized;
