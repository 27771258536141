import { getContractUploader, uploadData } from "../../Services/Data-Uploader";
import { HIDE_LOADER, SHOW_LOADER, SHOW_NOTIFICATION, GET_VENDOR_LIST, SET_VENDOR } from "../Types";
import { actionDispatch } from "./";
import { saveToLocal } from "../../utils/cache";
import { LOCAL_STORAGE_KEYS } from "../../utils/constants";

export const uploadDataAPI = async (obj, data) => {
  try {
    const res = await uploadData(obj, data);
    return res;
  } catch (error) {
    return error.response;
  }
};

export const getContractUploaderAPI = async (obj, data) => {
  try {
    const res = await getContractUploader(obj, data);
    return res;
  } catch (error) {
    return error.response;
  }
};
