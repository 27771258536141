import * as ActionTypes from "../actions/Types";

const initialState = {
  templates: [],
  selectedTemplate: {},
  templatedContract: {},
};

export const templatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_TEMPLATES:
      return { ...state, templates: action.payload };
    case ActionTypes.GET_TEMPLATE:
      return {
        ...state,
        selectedTemplate: action.payload,
      };
    case ActionTypes.GET_TEMPLATE_BY_CONTRACT_ID:
      return {
        ...state,
        templatedContract: action.payload,
      };
    case ActionTypes.UPDATE_TEMPLATED_CONTRACT:
      return {
        ...state,
        templatedContract: action.payload,
      };
    case ActionTypes.CLEAR_TEMPLATED_CONTRACT:
      return {
        ...state,
        templatedContract: action.payload,
      };
    default:
      return state;
  }
};
