import {
  getTopVendor,
  getTopSKU,
  getProcessTrend,
  getDisrepency,
  getTotalCount,
  getContractRenewal,
  getContractCount,
  getContractsAndInvoicesCount,
  getFreshContractsAndInvoices,
  getFreshContracts,
  getWeeklyContract,
  getWeeklyContractAndInvoice,
  getContractsOnMonthBasis,
  getPendingContracts,
  getProcessedContracts,
  getPOCount,
  getWeeklyPO,
  getPOWithDiscApproval,
  getPOWithRejectedStatus,
  getRecentPO,
  getPendingApprovalPOAndInvoiceCount,
  getWeeklyPoAndInvoices,
  getWeeklyContractPOsInvoices,
  getRecentEntriesOfEntities,
  getInvoiceCount,
  getWeeklyInvoice,
  getInvoiceWithDiscApproval,
  getInvoiceWithRejectedStatus,
  getRecentInvoice,
  getBackendCommitVersion,
  getCurrentMonthContractsPOsInvoices,
  getVendorScore,
  getSitesWithCostDiscrepancies,
} from "../../Services/Dashboard/dashboard";
import {
  HIDE_LOADER,
  SHOW_LOADER,
  SHOW_NOTIFICATION,
  GET_CONTRACTS_COUNT,
  GET_CONTRACT_AND_INVOICE_COUNT,
  GET_RECENT_CONTRACTS_AND_INVOICES,
  GET_RECENT_CONTRACTS,
  GET_RECENT_MONTHLY_CONTRACTS,
  GET_PENDING_CONTRACTS,
  GET_PROCESSED_CONTRACTS,
  GET_PO_COUNTS,
  GET_WEEKLY_PO,
  GET_DISC_APPROVAL_PO,
  GET_REJECTED_PO,
  GET_RECENT_PO,
  GET_PO_INVOICE_APPROVAL_COUNTS,
  GET_WEEKLY_PO_INVOICES,
  GET_RECENT_ENTITY_RECORDS,
  GET_WEEKLY_PO_CONTRACT_INVOICES,
  GET_INVOICE_COUNTS,
  GET_WEEKLY_INVOICE,
  GET_DISC_APPROVAL_INVOICE,
  GET_REJECTED_INVOICE,
  GET_RECENT_INVOICE,
  GET_COMMIT_VERSION,
  GET_CURRENT_MONTH_PO_CONTRACT_INVOICES,
} from "../Types";
import { UNPERFORMED_GET_ACTION } from "../../Constants/validation";
import { actionDispatch } from "./";
import { GET_CONTRACT_RENEWAL, GET_DISCREPENCY, GET_PROCESSING_TREND, GET_TOP_5_SKU, GET_TOP_5_VENDOR, GET_TOTAL_COUNT, GET_VENDOR_SCORE, GET_WEEKLY_CONTRACTS, GET_WEEKLY_CONTRACTS_AND_INVOICES } from "../Types/Dashboard";
export const getContractsCount = (id) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getContractCount(id);
    console.log(res, "ressss");
    dispatch(actionDispatch(GET_CONTRACTS_COUNT, res.data.contracts));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    console.log(ex);
    dispatch(actionDispatch(HIDE_LOADER));
  }
};

export const getContractAndInvoiceCount = (id) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getContractsAndInvoicesCount(id);
    dispatch(actionDispatch(GET_CONTRACT_AND_INVOICE_COUNT, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    console.log(ex);
    dispatch(actionDispatch(HIDE_LOADER));
  }
};

export const getRecentContractsAndInvoices = (id) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getFreshContractsAndInvoices(id);
    dispatch(actionDispatch(GET_RECENT_CONTRACTS_AND_INVOICES, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message: ex.response && ex.response.data ? ex.response.data.message : UNPERFORMED_GET_ACTION,
        open: true,
        type: "error",
      })
    );
  }
};

export const getRecentContracts = (id) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getFreshContracts(id);
    dispatch(actionDispatch(GET_RECENT_CONTRACTS, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    console.log(ex);
    dispatch(actionDispatch(HIDE_LOADER));
  }
};

export const getContractRenewals = (vendorId) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getContractRenewal(vendorId);
    dispatch(actionDispatch(GET_CONTRACT_RENEWAL, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message: ex.response && ex.response.data ? ex.response.data.message : UNPERFORMED_GET_ACTION,
        open: true,
        type: "error",
      })
    );
  }
};

export const getDisrepencies = (vendorId) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getDisrepency(vendorId);
    dispatch(actionDispatch(GET_DISCREPENCY, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message: ex.response && ex.response.data ? ex.response.data.message : UNPERFORMED_GET_ACTION,
        open: true,
        type: "error",
      })
    );
  }
};

export const getProcessTrends = (vendorId) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getProcessTrend(vendorId);
    dispatch(actionDispatch(GET_PROCESSING_TREND, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message: ex.response && ex.response.data ? ex.response.data.message : UNPERFORMED_GET_ACTION,
        open: true,
        type: "error",
      })
    );
  }
};

export const getTopSKUs = (vendorId) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getTopSKU(vendorId);
    dispatch(actionDispatch(GET_TOP_5_SKU, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message: ex.response && ex.response.data ? ex.response.data.message : UNPERFORMED_GET_ACTION,
        open: true,
        type: "error",
      })
    );
  }
};

export const getTopVendors = () => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getTopVendor();
    dispatch(actionDispatch(GET_TOP_5_VENDOR, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message: ex.response && ex.response.data ? ex.response.data.message : UNPERFORMED_GET_ACTION,
        open: true,
        type: "error",
      })
    );
  }
};

export const getVendorScores = (vendorId) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getVendorScore(vendorId);
    dispatch(actionDispatch(GET_VENDOR_SCORE, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message: ex.response && ex.response.data ? ex.response.data.message : UNPERFORMED_GET_ACTION,
        open: true,
        type: "error",
      })
    );
  }
};

export const getTotalCounts = (vendorId) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getTotalCount(vendorId);
    dispatch(actionDispatch(GET_TOTAL_COUNT, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message: ex.response && ex.response.data ? ex.response.data.message : UNPERFORMED_GET_ACTION,
        open: true,
        type: "error",
      })
    );
  }
};

export const getWeeklyContracts = (id) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getWeeklyContract(id);
    dispatch(actionDispatch(GET_WEEKLY_CONTRACTS, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message: ex.response && ex.response.data ? ex.response.data.message : UNPERFORMED_GET_ACTION,
        open: true,
        type: "error",
      })
    );
  }
};

export const getWeeklyContractsAndInvoices = (id) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getWeeklyContractAndInvoice(id);
    dispatch(actionDispatch(GET_WEEKLY_CONTRACTS_AND_INVOICES, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    console.log(ex);
    dispatch(actionDispatch(HIDE_LOADER));
  }
};

export const getContractsMonthWise = (id) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getContractsOnMonthBasis(id);
    dispatch(actionDispatch(GET_RECENT_MONTHLY_CONTRACTS, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    console.log(ex);
    dispatch(actionDispatch(HIDE_LOADER));
  }
};

export const getPendingContractsCount = (id) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getPendingContracts(id);
    dispatch(actionDispatch(GET_PENDING_CONTRACTS, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    console.log(ex);
    dispatch(actionDispatch(HIDE_LOADER));
  }
};

export const getProcessedContractsCount = (id) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getProcessedContracts(id);
    dispatch(actionDispatch(GET_PROCESSED_CONTRACTS, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    console.log(ex);
    dispatch(actionDispatch(HIDE_LOADER));
  }
};

export const getPOCounts = (id) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getPOCount(id);
    dispatch(actionDispatch(GET_PO_COUNTS, res.data.pos));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    console.log(ex);
    dispatch(actionDispatch(HIDE_LOADER));
  }
};

export const getWeeklyPOs = (id) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getWeeklyPO(id);
    dispatch(actionDispatch(GET_WEEKLY_PO, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    console.log(ex);
    dispatch(actionDispatch(HIDE_LOADER));
  }
};

export const getDiscApprovalPO = (id) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getPOWithDiscApproval(id);
    dispatch(actionDispatch(GET_DISC_APPROVAL_PO, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    console.log(ex);
    dispatch(actionDispatch(HIDE_LOADER));
  }
};

export const getRejectedPO = (id) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getPOWithRejectedStatus(id);
    dispatch(actionDispatch(GET_REJECTED_PO, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    console.log(ex);
    dispatch(actionDispatch(HIDE_LOADER));
  }
};

export const getRecentPOList = (id) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getRecentPO(id);
    dispatch(actionDispatch(GET_RECENT_PO, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    console.log(ex);
    dispatch(actionDispatch(HIDE_LOADER));
  }
};

export const getPendingApprovalPOAndInvoiceCounts = (id) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getPendingApprovalPOAndInvoiceCount(id);
    dispatch(actionDispatch(GET_PO_INVOICE_APPROVAL_COUNTS, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    console.log(ex);
    dispatch(actionDispatch(HIDE_LOADER));
  }
};

export const getWeeklyPOAndInvoice = (id) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getWeeklyPoAndInvoices(id);
    dispatch(actionDispatch(GET_WEEKLY_PO_INVOICES, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    console.log(ex);
    dispatch(actionDispatch(HIDE_LOADER));
  }
};

export const getRecentRecordEntries = (id) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getRecentEntriesOfEntities(id);
    dispatch(actionDispatch(GET_RECENT_ENTITY_RECORDS, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    console.log(ex);
    dispatch(actionDispatch(HIDE_LOADER));
  }
};

export const getWeeklyContractPOsInvoicesRecords = (id) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getWeeklyContractPOsInvoices(id);
    dispatch(actionDispatch(GET_WEEKLY_PO_CONTRACT_INVOICES, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    console.log(ex);
    dispatch(actionDispatch(HIDE_LOADER));
  }
};

export const getCurrentMonthPOsInvoicesContracts = (id) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getCurrentMonthContractsPOsInvoices(id);
    dispatch(actionDispatch(GET_CURRENT_MONTH_PO_CONTRACT_INVOICES, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    console.log(ex);
    dispatch(actionDispatch(HIDE_LOADER));
  }
};

export const getSitesWithCostDiscrepanciesAPI = async (id) => {
  try {
    const res = await getSitesWithCostDiscrepancies(id);
    return res;
  } catch (error) {
    return error.response;
  }
};

/// Actions for Invoice Manager ///

export const getInvoiceCounts = (id) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getInvoiceCount(id);
    dispatch(actionDispatch(GET_INVOICE_COUNTS, res.data.invoices));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    console.log(ex);
    dispatch(actionDispatch(HIDE_LOADER));
  }
};

export const getWeeklyInvoices = (id) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getWeeklyInvoice(id);
    dispatch(actionDispatch(GET_WEEKLY_INVOICE, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    console.log(ex);
    dispatch(actionDispatch(HIDE_LOADER));
  }
};

export const getDiscApprovalInvoices = (id) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getInvoiceWithDiscApproval(id);
    dispatch(actionDispatch(GET_DISC_APPROVAL_INVOICE, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    console.log(ex);
    dispatch(actionDispatch(HIDE_LOADER));
  }
};

export const getRejectedInvoices = (id) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getInvoiceWithRejectedStatus(id);
    dispatch(actionDispatch(GET_REJECTED_INVOICE, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    console.log(ex);
    dispatch(actionDispatch(HIDE_LOADER));
  }
};

export const getRecentInvoiceList = (id) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getRecentInvoice(id);
    dispatch(actionDispatch(GET_RECENT_INVOICE, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    console.log(ex);
    dispatch(actionDispatch(HIDE_LOADER));
  }
};

/// Git commit versioning ////
export const getBackendGitCommit = () => async (dispatch) => {
  try {
    const res = await getBackendCommitVersion();
    dispatch(actionDispatch(GET_COMMIT_VERSION, res.data));
    console.log(`b:${res.data}`);
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    console.log(ex);
    dispatch(actionDispatch(HIDE_LOADER));
  }
};
