import moment from "moment";

/// # of times template not found for Purchase Order And Invoice ///
export const PoInvoiceCountWithStatusColumns = [
  {
    title: "#",
    dataIndex: "serialNumber",
    width: 60,
    align: "center",
    render: (text, object, index) => index + 1,
  },
  {
    title: "Organization",
    dataIndex: "organization",
    render: (org) => org && org.name,
  },
  {
    title: "Vendor",
    dataIndex: "vendor",
    render: (vendor) => vendor && vendor.title,
  },
  // {
  //   title: "Amount",
  //   dataIndex: "amount",
  //   render: (amount) =>
  //     amount
  //       ? `$${amount
  //           .toFixed(2)
  //           .toString()
  //           .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
  //       : "N/A",
  // },
  {
    title: "Created At",
    dataIndex: "createdAt",
    render: (date) => moment(date).format("YYYY-MM-DD"),
  },
  {
    title: "Document Type",
    dataIndex: "po_number",
    render: (po_number) => (po_number === undefined ? "Invoice" : "PO"),
  },
];

/// # of processed purchase orders ///
export const PoInvoiceProcessedColumns = [
  {
    title: "#",
    dataIndex: "serialNumber",
    width: 60,
    align: "center",
    render: (text, object, index) => index + 1,
  },
  {
    title: "Organization",
    dataIndex: "organization",
    render: (org) => org && org.name,
  },
  {
    title: "Vendor",
    dataIndex: "vendor",
    render: (vendor) => vendor && vendor.title,
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    render: (date) => moment(date).format("YYYY-MM-DD"),
  },
  {
    title: "Amount",
    dataIndex: "amount",
    render: (amount) =>
      amount
        ? `$${amount
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
        : "N/A",
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (status) => (status ? status : "--"),
  },
  {
    title: "Document Type",
    dataIndex: "po_number",
    render: (po_number) => (po_number === undefined ? "Invoice" : "PO"),
  },
];

/// # of total contracts ///
export const ContractsTotalColumns = [
  {
    title: "#",
    dataIndex: "serialNumber",
    width: 60,
    align: "center",
    render: (text, object, index) => index + 1,
  },
  {
    title: "Organization",
    dataIndex: "organization",
    render: (org) => org && org.name,
  },
  {
    title: "Vendor",
    dataIndex: "vendor",
    render: (vendor) => vendor && vendor.title,
  },
  {
    title: "Template Type",
    dataIndex: "contract_type",
    render: (type) => type,
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    render: (date) => moment(date).format("YYYY-MM-DD"),
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (status) => (status ? status : "--"),
  },
];

/// Time taken from contracts upload to contract process
export const ContractsProcessedWithTimeDiffCols = [
  {
    title: "#",
    dataIndex: "serialNumber",
    width: 60,
    align: "center",
    render: (text, object, index) => index + 1,
  },
  {
    title: "Contract #",
    dataIndex: "contract_number",
    render: (contract_number) => (contract_number ? contract_number : "N/A"),
  },
  {
    title: "Organization",
    dataIndex: "organization.name",
    render: (org) => org,
  },
  {
    title: "Vendor",
    dataIndex: "vendor.title",
    render: (vendor) => vendor,
  },
  {
    title: "Template",
    dataIndex: "contract_type",
    render: (type) => type,
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    render: (date) => moment(date).format("LLL"),
  },
  {
    title: "Processed At",
    dataIndex: "sku_processed_time",
    render: (date) => moment(date).format("LLL"),
  },
  {
    title: "Processed Time",
    dataIndex: "contractProcessedTime",
    render: (processedTime) => (
      <b>
        {processedTime || processedTime === 0
          ? processedTime !== 0
            ? `${processedTime} hours`
            : "Less than an hour"
          : "--"}
      </b>
    ),
  },
];

/// User login frequency
export const userLoginFrequencyColumns = [
  {
    title: "#",
    dataIndex: "serialNumber",
    width: 60,
    align: "center",
    render: (text, object, index) => index + 1,
  },
  {
    title: "Organization",
    dataIndex: "organization",
    render: (org) => org && org.name,
  },
  {
    title: "Username",
    dataIndex: "user",
    render: (user) =>
      user
        ? `${
            user.first_name
              ? user.first_name
              : "" + " " + user.last_name
              ? user.last_name
              : ""
          }`
        : "--",
  },
  {
    title: "User email",
    dataIndex: "user",
    render: (user) => (user && user.email ? user.email : "--"),
  },
  {
    title: "Last Login",
    dataIndex: "createdAt",
    render: (createdAt) => moment(createdAt).format("MMMM Do YYYY, h:mm a"),
  },
  {
    title: "Role(s)",
    dataIndex: "roles",
    render: (roles) => roles && roles.map((role) => <p>{role} </p>),
  },
];

/// Approved and rejected Percentages for POs and Invoices
export const approvedRejectedPoInvoicePercentCols = [
  {
    title: "#",
    dataIndex: "serialNumber",
    width: 60,
    align: "center",
    render: (text, object, index) => index + 1,
  },
  {
    title: "Organization",
    dataIndex: "organization_name",
    render: (org) => org && org.name,
  },
  {
    title: "Approved Documents",
    dataIndex: "approved",
    render: (approved) => approved,
  },
  {
    title: "Rejected Documents",
    dataIndex: "rejected",
    render: (rejected) => rejected,
  },
  {
    title: "Approved percentage",
    dataIndex: "approved_percentage",
    render: (approved_percent) => `${approved_percent}%`,
  },
  {
    title: "Rejected percentage",
    dataIndex: "rejected_percentage",
    render: (rejected_percent) => `${rejected_percent}%`,
  },
  {
    title: "Total",
    dataIndex: "total",
    render: (total) => total,
  },
  {
    title: "Doc Type",
    dataIndex: "type",
  },
];

/// Discrepancy Aggregate for Purchase Orders
export const discrepancyAggregatePO = [
  {
    title: "#",
    dataIndex: "serialNumber",
    width: 60,
    align: "center",
    render: (text, object, index) => index + 1,
  },
  {
    title: "PO #",
    dataIndex: "po_number",
    render: (po_number) => (po_number ? po_number : "N/A"),
  },
  {
    title: "SKU Number",
    dataIndex: "sku_number",
    render: (sku_no) => sku_no,
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    render: (date) => moment(date).format("YYYY-MM-DD"),
  },
  {
    title: "Organization",
    dataIndex: "organization",
    render: (org) => org && org.name,
  },
  {
    title: "Vendor",
    dataIndex: "vendor",
    render: (vendor) => vendor && vendor.title,
  },
  {
    title: "Discrepancy Amount",
    dataIndex: "amount",
    render: (amount) =>
      amount
        ? `$${amount
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
        : "N/A",
  },
];

/// Discrepancy Aggregate for Invoices
export const discrepancyAggregateInvoice = [
  {
    title: "#",
    dataIndex: "serialNumber",
    width: 60,
    align: "center",
    render: (text, object, index) => index + 1,
  },
  {
    title: "Invoice #",
    dataIndex: "invoice_number",
    render: (invoice_number) => (invoice_number ? invoice_number : "N/A"),
  },
  {
    title: "SKU Number",
    dataIndex: "sku_number",
    render: (sku_no) => sku_no,
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    render: (date) => moment(date).format("YYYY-MM-DD"),
  },
  {
    title: "Organization",
    dataIndex: "organization",
    render: (org) => org && org.name,
  },
  {
    title: "Vendor",
    dataIndex: "vendor",
    render: (vendor) => vendor && vendor.title,
  },
  {
    title: "Discrepancy Amount",
    dataIndex: "amount",
    render: (amount) =>
      amount
        ? `$${amount
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
        : "N/A",
  },
];
