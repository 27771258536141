import { actionDispatch } from "./actionDispatcher";
import { SET_PAGINATION_FOR_CONTRACT, SET_PAGINATION_FOR_INVOICE, SET_PAGINATION_FOR_PO, SET_REDIRECT_STATUS_FOR_CONTRACT, SET_REDIRECT_STATUS_FOR_INVOICE, SET_REDIRECT_STATUS_FOR_PO } from "../Types";

export const setPagination = (options, type) => (dispatch) => {
    type==="contract" && dispatch(actionDispatch(SET_PAGINATION_FOR_CONTRACT, options));
    type==="invoice" && dispatch(actionDispatch(SET_PAGINATION_FOR_INVOICE, options));
    type==="po" &&  dispatch(actionDispatch(SET_PAGINATION_FOR_PO, options));
  }
  
  export const setRedirectStatus = (status, type) => (dispatch) => {
    type==="contract" && dispatch(actionDispatch(SET_REDIRECT_STATUS_FOR_CONTRACT, status));
    type==="invoice" && dispatch(actionDispatch(SET_REDIRECT_STATUS_FOR_INVOICE, status));
    type==="po" && dispatch(actionDispatch(SET_REDIRECT_STATUS_FOR_PO, status));
  
  }