import HttpRequest from "../../utils/httpRequests";
import endPoints from "../../utils/endPoints";

export const getAllVendors = () => {
  return HttpRequest("get", endPoints.GET_ALL_VENDORS);
}

export const getVendorListWrtOrg = (org_id) => {
  return HttpRequest("get", `${endPoints.GET_VENDOR_LIST_WRT_ORG}${org_id}`);
}

