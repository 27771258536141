import { getAllVendors, getVendorListWrtOrg } from "../../Services/Vendors/vendor";
import { HIDE_LOADER, SHOW_LOADER, SHOW_NOTIFICATION, GET_VENDOR_LIST, SET_VENDOR } from '../Types';
import { actionDispatch } from "./";
import { saveToLocal } from '../../utils/cache';
import { LOCAL_STORAGE_KEYS } from '../../utils/constants';

export const getVendors = (org_id) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = org_id ? await getVendorListWrtOrg(org_id) : await getAllVendors();
    dispatch(actionDispatch(GET_VENDOR_LIST, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message: ex.msg,
        open: true,
        type: "error",
      })
    );
  }
};

export const setVendor = (vendorId) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    saveToLocal(vendorId, LOCAL_STORAGE_KEYS.SELECTED_VENDOR, true, true);
    dispatch(actionDispatch(SET_VENDOR, { selectedVendor: vendorId }));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message: ex.msg,
        open: true,
        type: 'error',
      }),
    );
  }
};
