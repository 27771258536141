import { getPoCompliance } from "../../Services/PO_Compliance";

export const getPoComplianceAPI = async (search) => {
  try {
    const res = await getPoCompliance(search);
    return res;
  } catch (error) {
    return error.response;
  }
};
