import { createPOs, getPOList, getPO, updatePO, removePO, createDiscrepancyFoundInPO, createPoManuallyAPI, updatePOAndPOItems, recalculatePODiscrepancy, updatePOStatusWithComment, getPosForManager } from "../../Services/PO/po";
import { getPoFileAPI, uploadPODocs } from "../../Services/Documents/document";
import { HIDE_LOADER, SHOW_LOADER, SHOW_NOTIFICATION, VIEW_ALL_PO, VIEW_PO, UPDATE_PO, DELETE_PO, CREATE_PO_MANUALLY, GET_PO_FILE, CLEAR_PO_FILE_URL, SHOW_FILE_LOADER, HIDE_FILE_LOADER } from "../Types";
import { VIEW_PO_ROUTE } from "../../Constants/routeNames";
import { actionDispatch, triggerNotification } from "./";
import { uploadToS3 } from "../../utils/s3Upload";
import { UNPERFORMED_GET_ACTION, UNPERFORMED_SAVE_ACTION, UNPERFORMED_UPDATE_ACTION, PO_SAVED_SUCCESSFULLY, DISCREPANCY_ADDED_SUCCESS } from "../../Constants/validation";
import history from "../../History/history";

export const addPOs = (data, userId) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    //create PO and get signedUrl in response
    const res = await createPOs(data);
    const temp = [];
    //Map over array and upload each PO on url
    res.data.forEach(async (po, i) => {
      await uploadToS3(po.signedUrl, data[i].originFileObj);
      temp.push({
        po_id: po.id,
        user_id: userId,
        type: "po",
        key: po.file_key,
        name: data[i].name,
      });
      //creating docs after successfully uploaded files
      if (temp.length == res.data.length) {
        const uploadRes = await uploadPODocs(temp);
        if (uploadRes.success) {
          dispatch(actionDispatch(HIDE_LOADER));
          history.push(`${VIEW_PO_ROUTE}`);
        }
        dispatch(
          actionDispatch(SHOW_NOTIFICATION, {
            message: PO_SAVED_SUCCESSFULLY,
            open: true,
            type: "success",
          })
        );
      }
    });
    //Hit Document API
  } catch (ex) {
    console.log(ex);
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message: ex.response && ex.response.data && typeof ex.response.data.message == "string" ? ex.response.data.message : UNPERFORMED_SAVE_ACTION,
        open: true,
        type: "error",
      })
    );
  }
};

export const getPOById = (id) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getPO(id);
    dispatch(actionDispatch(VIEW_PO, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message: ex.response && ex.response.data ? ex.response.data.message : UNPERFORMED_GET_ACTION,
        open: true,
        type: "error",
      })
    );
  }
};

export const updatePOById = (id, data) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const response = await updatePO(id, data);
    dispatch(actionDispatch(UPDATE_PO, data));
    if (response && response.success) {
      dispatch(actionDispatch(HIDE_LOADER));
      dispatch(
        actionDispatch(SHOW_NOTIFICATION, {
          message: response && response.message,
          open: true,
          type: "success",
        })
      );
      history.push(`${VIEW_PO_ROUTE}`);
    }
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message: ex.response && ex.response.data ? ex.response.data.message : UNPERFORMED_UPDATE_ACTION,
        open: true,
        type: "error",
      })
    );
  }
};

export const getPOs =
  (org_id, params, isApprover = false) =>
  async (dispatch) => {
    dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
    try {
      let res;
      if (isApprover) {
        res = await getPosForManager(org_id, params);
      } else {
        res = await getPOList(org_id, params);
      }
      dispatch(actionDispatch(VIEW_ALL_PO, res.data));
      dispatch(actionDispatch(HIDE_LOADER));
    } catch (ex) {
      dispatch(actionDispatch(HIDE_LOADER));
      dispatch(
        actionDispatch(SHOW_NOTIFICATION, {
          message: ex.response && ex.response.data ? ex.response.data.message : UNPERFORMED_GET_ACTION,
          open: true,
          type: "error",
        })
      );
    }
  };

export const deletePO = (id, existingData) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const response = await removePO(id);
    let data = { ...existingData };
    data.rows = data.rows.filter((d) => d.id !== id);
    dispatch(actionDispatch(DELETE_PO, data));
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message: response.message,
        open: true,
        type: "success",
      })
    );
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message: ex.response.data.message,
        open: true,
        type: "error",
      })
    );
  }
};

export const createDiscrepancyForPO = (data) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await createDiscrepancyFoundInPO(data);
    dispatch(actionDispatch(HIDE_LOADER));
    if (res.success) {
      dispatch(actionDispatch(HIDE_LOADER));
      history.push(`${VIEW_PO_ROUTE}`);
    }
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message: DISCREPANCY_ADDED_SUCCESS,
        open: true,
        type: "success",
      })
    );
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message: ex.response && ex.response.data ? ex.response.data.message : UNPERFORMED_GET_ACTION,
        open: true,
        type: "error",
      })
    );
  }
};

export const createPoManually = (data) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const response = await createPoManuallyAPI(data);
    dispatch(actionDispatch(CREATE_PO_MANUALLY, response));
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(triggerNotification("success", response.message));
    history.push("/po/view");
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(triggerNotification("error", ex.message));
  }
};

export const updatePOWithPOItems = (id, data, historyData, shouldRecheck) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const response = await updatePOAndPOItems(id, data);
    response.success && (await updatePOStatusWithComment(id, historyData));
    //If user confirms PO recheck discrepancies and send reRun value as true
    const recheckDisc = response.success && shouldRecheck && (await (id, { reRun: true }));
    if (shouldRecheck && recheckDisc.success) {
      dispatch(actionDispatch(HIDE_LOADER));
      dispatch(
        actionDispatch(SHOW_NOTIFICATION, {
          message: response && response.message,
          open: true,
          type: "success",
        })
      );
      history.push(`${VIEW_PO_ROUTE}`);
    }
    //Otherwise simply update and send back response
    else if (!shouldRecheck && response && response.success) {
      dispatch(actionDispatch(HIDE_LOADER));
      dispatch(
        actionDispatch(SHOW_NOTIFICATION, {
          message: response && response.message,
          open: true,
          type: "success",
        })
      );
      history.push(`${VIEW_PO_ROUTE}`);
    }
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message: ex.response && ex.response.data ? ex.response.data.message : UNPERFORMED_UPDATE_ACTION,
        open: true,
        type: "error",
      })
    );
  }
};

export const getPoFile = (key) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  dispatch(actionDispatch(SHOW_FILE_LOADER));
  try {
    const res = await getPoFileAPI(key);
    dispatch(actionDispatch(GET_PO_FILE, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(actionDispatch(HIDE_FILE_LOADER));
  } catch (ex) {
    dispatch(actionDispatch(HIDE_FILE_LOADER));
    dispatch(actionDispatch(HIDE_LOADER));
    triggerNotification("error", ex);
  }
};

export const clearPoFileUrl = () => (dispatch) => {
  dispatch(actionDispatch(CLEAR_PO_FILE_URL, {}));
};
