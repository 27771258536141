import HttpRequest from "../../utils/httpRequests";
import endPoints from "../../utils/endPoints";


export const login = async (data) => {
  return await HttpRequest("post", endPoints.LOGIN, data);
};

export const forgotUserPassword = (data) => {
  return HttpRequest("post", endPoints.FORGOT_PASSOWRD, data);
};
export const resetUserPassword = (data, tk) => {
  return HttpRequest("post", endPoints.RESET_PASSWORD, data, tk);
};
export const requestDemoApp = (data) => {
  return HttpRequest("post", endPoints.DEMO_APP_FORM, data);
};

export const isAuthorized = (roleList, rolesArr, doesNotInclude="") => {
  const myRoles = rolesArr.length
    ? rolesArr.map((roles) => roles.role.name)
    : [];

  return myRoles.some((r) => roleList.includes(r) && !myRoles.includes(doesNotInclude))
};

export const checkSession = (id) => {
  return HttpRequest('get', `${endPoints.SESSION}`);
};
