import HttpRequest from "../../utils/httpRequests";
import endPoints from "../../utils/endPoints";
export const getContractCount = (id) => {
  return HttpRequest("get", `${endPoints.CONTRACT_COUNT}/${id}`);
};

export const getContractsAndInvoicesCount = (id) => {
  return HttpRequest("get", `${endPoints.CONTRACT_AND_INVOICE_COUNT}/${id}`);
};

export const getFreshContractsAndInvoices = (id) => {
  return HttpRequest("get", `${endPoints.RECENT_CONTRACTS_AND_INVOICES}/${id}`);
};

export const getFreshContracts = (id) => {
  return HttpRequest("get", `${endPoints.RECENT_CONTRACTS}/${id}`);
};

export const getContractRenewal = (vendorId) => {
  return HttpRequest("get", `${endPoints.GET_CONTRACT_RENEWAL}/${vendorId}`);
};

export const getTotalCount = (vendorId) => {
  return HttpRequest("get", `${endPoints.GET_TOTAL_COUNT}/${vendorId}`);
};

export const getDisrepency = (vendorId) => {
  return HttpRequest("get", `${endPoints.GET_DISCREPENCY}/${vendorId}`);
};

export const getProcessTrend = (vendorId) => {
  return HttpRequest("get", `${endPoints.GET_PROCESSING_TREND}/${vendorId}`);
};

export const getTopSKU = (vendorId) => {
  return HttpRequest("get", `${endPoints.GET_TOP_5_SKU}/${vendorId}`);
};

export const getTopVendor = () => {
  return HttpRequest("get", `${endPoints.GET_TOP_5_VENDOR}`);
};

export const getVendorScore = (vendorId) => {
  return HttpRequest("get", `${endPoints.GET_VENDOR_SCORE}/${vendorId}`);
};

export const getWeeklyContract = (id) => {
  return HttpRequest("get", `${endPoints.GET_WEEKLY_CONTRACTS}/${id}`);
};

export const getWeeklyContractAndInvoice = (id) => {
  return HttpRequest("get", `${endPoints.GET_WEEKLY_CONTRACTS_AND_INVOICES}/${id}`);
};

export const getContractsOnMonthBasis = (id) => {
  return HttpRequest("get", `${endPoints.GET_RECENT_MONTHLY_CONTRACTS}/${id}`);
};

export const getPendingContracts = (id) => {
  return HttpRequest("get", `${endPoints.GET_PENDING_CONTRACTS}/${id}`);
};

export const getProcessedContracts = (id) => {
  return HttpRequest("get", `${endPoints.GET_PROCESSED_CONTRACTS}/${id}`);
};

//// Purchase Order Dashboard ////

export const getPOCount = (id) => {
  return HttpRequest("get", `${endPoints.GET_PO_COUNT}/${id}`);
};

export const getWeeklyPO = (id) => {
  return HttpRequest("get", `${endPoints.GET_WEEKLY_PO}/${id}`);
};

export const getPOWithDiscApproval = (id) => {
  return HttpRequest("get", `${endPoints.GET_PO_WITH_DISC_APPROVAL}/${id}`);
};

export const getPOWithRejectedStatus = (id) => {
  return HttpRequest("get", `${endPoints.GET_PO_WITH_REJECTED}/${id}`);
};

export const getRecentPO = (id) => {
  return HttpRequest("get", `${endPoints.GET_RECENT_PO}/${id}`);
};

//// Billing Manager /////

export const getPendingApprovalPOAndInvoiceCount = (id) => {
  return HttpRequest("get", `${endPoints.GET_PENDING_APPROVAL_PO_INVOICE}/${id}`);
};

export const getWeeklyPoAndInvoices = (id) => {
  return HttpRequest("get", `${endPoints.GET_WEEKLY_PO_INVOICES}/${id}`);
};

export const getRecentEntriesOfEntities = (id) => {
  return HttpRequest("get", `${endPoints.GET_RECENT_CONTRACTS_PO_INVOICES}/${id}`);
};

export const getWeeklyContractPOsInvoices = (org_id, params) => {
  return HttpRequest("get", `${endPoints.GET_WEEKLY_CONTRACT_PO_INVOICE}${org_id}`, null, null, params);
};

export const getCurrentMonthContractsPOsInvoices = (org_id) => {
  return HttpRequest("get", `${endPoints.GET_CURRENT_MONTH_CONTRACTS_POS_INVOICES}/${org_id}`);
};
export const getSitesWithCostDiscrepancies = (id) => {
  return HttpRequest("get", `${endPoints.GET_TOP_SITES_WITH_COST_DISCREPANCIES}/${id}`);
};

//// Invoice Manager Dashboard ////

export const getInvoiceCount = (id) => {
  return HttpRequest("get", `${endPoints.GET_INVOICE_COUNT}/${id}`);
};

export const getWeeklyInvoice = (id) => {
  return HttpRequest("get", `${endPoints.GET_WEEKLY_INVOICE}/${id}`);
};

export const getInvoiceWithDiscApproval = (id) => {
  return HttpRequest("get", `${endPoints.GET_INVOICE_WITH_DISC_APPROVAL}/${id}`);
};

export const getInvoiceWithRejectedStatus = (id) => {
  return HttpRequest("get", `${endPoints.GET_INVOICE_WITH_REJECTED}/${id}`);
};

export const getRecentInvoice = (id) => {
  return HttpRequest("get", `${endPoints.GET_RECENT_INVOICE}/${id}`);
};

/// Git commit versioning ///
export const getBackendCommitVersion = () => {
  return HttpRequest("get", `${endPoints.GET_BACKEND_COMMIT_VERSION}`);
};
